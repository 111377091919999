import React, { useState } from "react";
import { DatePicker, DatePickerProps } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";

import { Nullish } from "@/utils/types";

export interface FormDatePickerProps {
  datePickerProps?: Partial<DatePickerProps<Dayjs>>;
  disabled?: boolean;
  label: string;
  name: string;
  onChange?: (date: Nullish<string>) => void;
  value: Nullish<Date | string>;
}

/**
 * A component that combines a DatePicker and a hidden input[type="date"] element.
 * Allows drop-in use in regular <form> elements.
 */
const FormDatePicker: React.FC<FormDatePickerProps> = ({
  datePickerProps,
  disabled,
  label,
  name,
  onChange,
  value: initialValue,
}) => {
  const [inputValue, setInputValue] = useState<string>(
    () => dayjs(initialValue).format("YYYY-MM-DD") ?? "",
  );

  const value: Nullish<Dayjs> = dayjs(inputValue).isValid()
    ? dayjs(inputValue)
    : null;

  const handleDateChange = (date: Nullish<Dayjs>) => {
    const dateStr = date ? date.format("YYYY-MM-DD") : "";
    setInputValue(dateStr);
    onChange?.(dateStr);
  };

  return (
    <>
      <DatePicker
        disabled={disabled}
        label={label}
        value={value}
        onChange={handleDateChange}
        format="YYYY-MM-DD"
        sx={{ width: "100%" }}
        {...datePickerProps}
      />

      <input
        aria-hidden
        disabled={disabled}
        type="date"
        name={name}
        onChange={() => console.error("Should not be called")}
        value={inputValue}
        style={{ display: "none" }}
      />
    </>
  );
};

export default FormDatePicker;
