import { useState } from "react";
import { useSnackbar } from "notistack";
import {
  Box,
  Button,
  Card as MuiCard,
  CardActions,
  CardContent,
  CircularProgress,
  Typography,
} from "@mui/material";

import { Nullish } from "@/utils/types";

import { AutocompleteState, Field } from "./shared";
import CardHeader from "./CardHeader";
import ReadField from "./ReadField";
import WriteField from "./WriteField";

export const errorAt = (location: string) =>
  `Fel vid ${location}. Hör av dig till support eller försök igen senare.`;

export interface CardProps {
  title: string;
  tooltip?: string;
  layout: Field[][];
  isCompact?: boolean;
  onSubmit?: (
    values: { [k: string]: FormDataEntryValue },
    form: HTMLFormElement,
    autoCompleteState?: Record<string, AutocompleteState[]>,
  ) => Promise<Nullish<string>>;
  isEditable?: boolean;
}

/**
 * A flexible card that allows both read-only and editable fields.
 * `layout` takes a declerative array of fields.
 *
 * @param `onSubmit` should return a `string`, used as the success snackbar message.
 *         `Nullish` values are interpreted as "nothing changed".
 * Failed sumbissions should throw an error, used as the error snackbar message.
 */
export const Card: React.FC<CardProps> = ({
  title,
  tooltip,
  layout,
  isCompact = false,
  onSubmit,
  isEditable = false,
}) => {
  const [disabled, setDisabled] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const isEditing = isEditable && editMode;

  /** Autocomplete uses a separate state where changes are observed and tracked manually. */
  const [autocompleteState, setAutocompleteState] =
    useState<Record<string, AutocompleteState[]>>();
  const updateAutoCompleteState = (
    name: string,
    value: AutocompleteState[],
  ) => {
    setAutocompleteState({
      ...autocompleteState,
      [name]: value,
    });
  };

  const { enqueueSnackbar } = useSnackbar();

  let rowGap = 3;
  if (isCompact) {
    if (isEditing) rowGap = 2;
    else rowGap = 1;
  }

  return (
    <MuiCard>
      <CardHeader
        isDisabled={disabled}
        title={title}
        isEditable={isEditable}
        toggled={editMode}
        onChange={setEditMode}
      />

      <Box
        sx={{ position: "relative" }}
        component={"form"}
        onSubmit={async (event) => {
          if (!onSubmit) throw new Error("No onSubmit function provided.");
          event.preventDefault();

          const form = event.currentTarget;
          const formData = new FormData(form);
          const values = Object.fromEntries(formData);

          setDisabled(true);

          try {
            const success = await onSubmit(values, form, autocompleteState);
            enqueueSnackbar(success);

            setEditMode(false);
            setDisabled(false);
          } catch (error) {
            enqueueSnackbar(errorAt("uppladding av minnesrumsbilder"), {
              variant: "error",
            });
            // Don't exit edit mode (and clear fields) on error. Give the user the chance to save/fix.
            setDisabled(false);
          }
        }}
      >
        {disabled && isEditing && (
          <Box
            sx={{ position: "absolute", top: "40%", left: "45%", zIndex: 10 }}
          >
            <CircularProgress />
          </Box>
        )}

        <CardContent sx={{ opacity: disabled && isEditing ? 0.5 : 1 }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: rowGap }}>
            {layout.map((row, i) => (
              <Box
                key={i}
                sx={{
                  display: "flex",
                  gap: rowGap,
                  "@media (max-width: 700px)": { flexDirection: "column" },
                  "> *": { flex: 1 },
                }}
              >
                {row.map((field, j) =>
                  isEditing ? (
                    <WriteField
                      key={j}
                      isDisabled={disabled || (field.disabled ?? false)}
                      isCompact={isCompact}
                      updateAutoCompleteState={updateAutoCompleteState}
                      {...field}
                    />
                  ) : (
                    <ReadField key={j} isCompact={isCompact} {...field} />
                  ),
                )}
              </Box>
            ))}
          </Box>
        </CardContent>

        {isEditing && (
          <CardActions
            sx={{
              padding: [1, 2, 3, 2],
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              disabled={disabled}
              variant="contained"
              size="medium"
              type="submit"
            >
              Spara
            </Button>
          </CardActions>
        )}

        {tooltip && (
          <Box sx={{ padding: [1, 2, 3, 2] }}>
            <Typography variant="body2" sx={{ color: "grey.600" }}>
              {tooltip}
            </Typography>
          </Box>
        )}
      </Box>
    </MuiCard>
  );
};
