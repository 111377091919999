import { memo } from "react";
import { Box, BoxProps, Card, CardProps, Typography } from "@mui/material";

export interface ErrorCardProps extends CardProps {
  containerProps?: BoxProps;
  errorMessage: string;
}

const ErrorCard: React.FC<ErrorCardProps> = ({
  containerProps,
  errorMessage,
  sx,
  ...props
}) => (
  <Box
    {...containerProps}
    sx={{
      position: "relative",
      minWidth: 600,
      overflow: "scroll",
      height: "100%",
      flexGrow: 1,
      justifyContent: "flex-start",
      alignItems: "stretch",
      backgroundColor: "grey.100",
      ...containerProps?.sx,
    }}
  >
    <Card
      sx={{
        m: 1,
        p: 4,
        maxWidth: "500px",
        transform: "translate(-8px, -8px)",
        ...sx,
      }}
      {...props}
    >
      <Box>
        <Typography variant="body1">{errorMessage}</Typography>
      </Box>
    </Card>
  </Box>
);

export default memo(ErrorCard);
