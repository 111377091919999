import { Nullish } from "@/utils/types";

export const NON_BREAKING_SPACE = "\u00A0";
export const CURRENCY = "kr" as const;

/**
 * Formats a price value by applying an optional factor and appending `CURRENCY`.
 *
 * @throws {Error} Throws an error if the price string cannot be converted to a number.
 *
 * @example
 * formatPrice("2000") => "2&bnsp000&nbspkr"
 */
export function price(price: number | string) {
  if (typeof price === "number") {
    price = String(price);
  }

  if (price.includes(".")) {
    const [whole] = price.split(".");
    price = whole!;
  }

  return `${price.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    NON_BREAKING_SPACE,
  )}${NON_BREAKING_SPACE}${CURRENCY}`;
}

/**
 * Normalizes and formats a Swedish phone number.
 *
 * The function checks if the input string is a valid Swedish phone number, removes any white spaces, hyphens, and leading '+46'.
 * It then ensures the number starts with '0' if it didn't start with '+46'.
 * Finally, it formats the number based on whether it starts with '7' or not.
 *
 * Note: The function expects the input to be a string that represents a valid Swedish phone number.
 * The phone number should start with either '0' or '+46' and should be 8 or 9 digits long excluding the country code.
 * If the input is not a valid Swedish phone number, the function will return `null`.
 *
 * @example
 * swedishPhoneNumber('+46701234567'); => '070—123 45 67'
 * swedishPhoneNumber('081234567');    => '08—123 45 67'
 * swedishPhoneNumber('1234567');      => null
 */
export function swedishPhoneNumber(phoneNumber: string): Nullish<string> {
  // Check if the string is a valid swedish phone number
  if (!/^(0|\+46)\d{8,9}$/.test(phoneNumber)) {
    return null;
  }

  // Remove white space, hyphens, and any leading '+46'
  let normalized = phoneNumber.replace(/\s|-|\+46/g, "");

  // If the number did not start with '+46', it should start with '0'
  if (normalized.charAt(0) !== "0") normalized = "0" + normalized;

  return normalized.charAt(1) == "7"
    ? normalized.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, "$1—$2 $3 $4")
    : normalized.replace(/(\d{2})(\d{3})(\d{2})(\d{2})/, "$1—$2 $3 $4");
}

export const listFormatDisjunction = new Intl.ListFormat("sv", {
  type: "disjunction",
});
export const listFormatConjunction = new Intl.ListFormat("sv", {
  type: "conjunction",
});

const FIRST_SPACE = /^(.*?)\s/;

/**
 * Splits a full name into first and last names based on the first space.
 * If no space is found, the entire name is considered as the first name and the last name is an empty string.
 *
 * @example
 * const [firstName, lastName] = splitName('John Doe'); => ["John", "Doe"]
 *
 * @example
 * const [firstName, lastName] = splitName('Madonna'); => ["Madonna", ""]
 */
export function splitName(name: string): [string, string] {
  if (name === "") return ["", ""];

  const [fallbackWhenNoSpace, firstName, lastName] = name
    .trim()
    .split(FIRST_SPACE);
  return [firstName ?? fallbackWhenNoSpace!, lastName ?? ""];
}

/**
 * Capitalizes the first letter of a given string.
 *
 * @example
 * capitalizeFirstLetter("hello"); => "Hello"
 */
export function capitalizeFirstLetter(string: string) {
  return `${string.charAt(0).toUpperCase()}${string.slice(1)}`;
}

/**
 * Generates a `mailto:` link with the given parameters.
 *
 * @example
 * const link = mailtoLink({
 *   to: 'example@example.com',
 *   cc: 'cc@example.com',
 *   bcc: 'bcc@example.com',
 *   subject: 'Hello',
 *   body: 'World'
 * }); => "mailto:?to=example@example.com&cc=cc@example.com&bcc=bcc@example.com&subject=Hello&body=World"
 *
 * @remark This function replaces `+` with `%20` in the generated URL,
 * as `URLSearchParams` encodes spaces as `+`.
 */
export function mailtoLink(params: {
  to?: string;
  cc?: string;
  bcc?: string;
  subject?: string;
  body?: string;
}) {
  const url = new URL("mailto:");
  if (params.to) url.searchParams.append("to", params.to);
  if (params.cc) url.searchParams.append("cc", params.cc);
  if (params.bcc) url.searchParams.append("bcc", params.bcc);
  if (params.subject) url.searchParams.append("subject", params.subject);
  if (params.body) url.searchParams.append("body", params.body);

  // `SearchParams` encodes spaces as `+` instead of `%20`.
  return url.toString().replace(/\+/g, "%20");
}

/**
 * Transforms a Swedish name (or other word) to genetive case.
 *
 * Words containing all uppercase and having a length equal to or longer than
 * two letters are treated as abbreviations that are read out loud letter by
 * letter. These get suffixed by a colon followed by an `s`.
 * ([Reference](https://frageladan.isof.se/visasvar.py?svar=44948))
 * ```text
 * CSN   -> CSN:s
 * ```
 *
 * Words ending with `s`, `z` or `x` remain unchanged.
 * ```text
 * Elias -> Elias
 * ```
 *
 * All remaining words get suffixed by an `s`.
 * ```text
 * Love  -> Loves
 * ```
 *
 * [Reference](https://frageladan.isof.se/visasvar.py?svar=36117)
 *
 * @example
 * genetive("Elias"); => "Elias"
 * @example
 * genetive("CSN");   => "CSN:s"
 * @example
 * genetive("Love");  => "Loves"
 */
export function genetive(word: string) {
  if (word.length >= 2 && word === word.toLocaleUpperCase("sv")) {
    return `${word}:s`;
  }

  if (word.endsWith("s") || word.endsWith("z") || word.endsWith("x")) {
    return word;
  }

  return `${word}s`;
}

export type MetricUnit = "cm" | "mm" | "m" | "km";

/**
 * Format and round a `number` to closest one point float of `unit`.
 * @example
 * metric(1.5, 'cm') => '1.5 cm'
 * metric(1400.468, 'cm') => '1400.5 cm'
 */
export function metric(value: number, unit: MetricUnit) {
  return `${value.toFixed(1)} ${unit}`;
}
