import { memo, useCallback, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Divider,
  FormControlLabel,
  Paper,
  PaperProps,
  Stack,
  SxProps,
  Switch,
  Theme,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import * as Bananas from "bananas-commerce-admin";

import SubtitledLabel from "@/components/SubtitledLabel";
import { Nullish, Timedate } from "@/utils/types";

import AdMeta from "@/extensions/obituary/components/AdMeta";
import AdStatusBadge from "@/extensions/obituary/components/AdStatusBadge";
import PublishDateDialog from "@/extensions/obituary/components/PublishDateDialog";
import {
  Ad,
  PatchAdPayload,
  layoutName,
  publicationName,
} from "@/extensions/obituary/types";

const REMOVE_AD_CONFIRMATION =
  "Vill du verkligen ta bort annonsen? Detta går inte att ångra.";

const styles: Record<string, SxProps<Theme>> = {
  root: {
    display: "flex",
    flexDirection: "row",
    gap: 1,
    width: "100%",
    maxWidth: 900,
  },

  figure: {
    bgcolor: (theme: Theme) => theme.palette.grey[200],
    p: 2,
    m: 0,
    borderRight: (theme: Theme) => `1px solid ${theme.palette.grey[300]}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: 200,
    maxHeight: 600,
    minWidth: 200,
    maxWidth: 200,
  },

  preview: {
    objectFit: "contain",
    width: "100%",
    height: "100%",
  },

  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },

  body: {
    height: "100%",
  },

  approve: {
    display: "flex",
    flexDirection: "row-reverse",
    width: "100%",
    justifyContent: "space-between",
    ml: 0,
    mr: 0,
  },
};

export interface AdCardProps extends PaperProps {
  ad: Ad;
  onApprove?: (ad: Ad) => void;
  onChangeDate?: (ad: Ad) => void;
  onDeleted: (ad: Ad) => void;
  onEdit: (ad: Ad) => void;
}

const AdCard: React.FC<AdCardProps> = ({
  ad,
  onApprove,
  onChangeDate,
  onDeleted,
  onEdit,
  ...props
}) => {
  const api = Bananas.useApi();
  const { enqueueSnackbar } = useSnackbar();
  const [dateDialogOpen, setDateDialogOpen] = useState(false);

  const isApproved = ad.approved_at != null;

  /// Callbacks

  const updateAd = useCallback(
    async (payload: PatchAdPayload) => {
      const action = api.operations["obituary.ad:update"];
      if (action == null) throw new Error('No action "obituary.ad:update"');

      const response = await action.call({
        params: { obituary_ad_id: ad.id },
        body: payload,
      });

      if (response.ok) {
        const updatedAd = (await response.json()) as Ad;
        return updatedAd;
      } else {
        enqueueSnackbar(
          "Fel vid uppdatering av korrekturstatus. Försök igen eller kontakta support om felet kvarstår.",
          { variant: "error" },
        );
      }
    },
    [ad, api],
  );

  const handleApprove = useCallback(async () => {
    if (isApproved && !confirm("Vill du neka godkännandet?")) return;
    const updatedAd = await updateAd({ approve: !isApproved });
    if (updatedAd != null) onApprove?.(updatedAd);
  }, [ad, api]);

  const handleChangeDate = useCallback(
    async (
      publication_date: Nullish<Timedate>,
      editing_deadline: Nullish<Timedate>,
    ) => {
      if (publication_date == null || editing_deadline == null) return;

      const updatedAd = await updateAd({
        deadline: { publication_date, editing_deadline },
      });
      if (updatedAd != null) onChangeDate?.(updatedAd);
    },
    [ad],
  );

  const handleDelete = useCallback(async () => {
    if (!confirm(REMOVE_AD_CONFIRMATION)) return;

    const action = api.operations["obituary.ad:delete"];
    if (!action) throw new Error('Invalid action "obituary.ad:delete"');

    const response = await action.call({ params: { obituary_ad_id: ad.id } });
    if (response.ok) {
      onDeleted?.(ad);
    } else {
      enqueueSnackbar(
        "Fel vid borttagning av annons. Försök igen eller kontakta support om felet kvarstår.",
        { variant: "error" },
      );
    }
  }, [api, ad.id]);

  const handleEdit = useCallback(() => onEdit?.(ad), [ad]);

  const handleCloseDateDialog = useCallback(() => setDateDialogOpen(false), []);
  const handleOpenDateDialog = useCallback(() => setDateDialogOpen(true), []);

  return (
    <>
      <Paper sx={styles.root} component="article" {...props}>
        {ad.preview_url && ad.preview_url !== "" && (
          <Box
            component="figure"
            aria-label="Förhandsvisning"
            sx={styles.figure}
          >
            <Box component="img" src={ad.preview_url} sx={styles.preview} />
          </Box>
        )}

        <Stack p={2} gap={2} sx={{ width: "100%" }}>
          <Stack component="header" sx={styles.header}>
            <Stack>
              <Typography variant="h5">
                {publicationName[ad.publication_abbreviation]}
              </Typography>

              <Typography variant="subtitle1">
                {layoutName[ad.layout_variant]}
              </Typography>
            </Stack>

            <Box>
              <AdStatusBadge type={ad.status} />
            </Box>
          </Stack>

          <Stack gap={2} sx={styles.body} divider={<Divider flexItem />}>
            {ad.status === "rejected" && (
              <Alert severity="error">
                {ad.rejected_reason ?? "Ett fel har inträffat i annonsen."}
              </Alert>
            )}

            <AdMeta ad={ad} showPublishInfo />

            <FormControlLabel
              control={<Switch defaultChecked={isApproved} />}
              onChange={handleApprove}
              sx={styles.approve}
              label={
                <SubtitledLabel
                  sx={{ gap: 0.5 }}
                  title="Godkänd korrektur"
                  subtitle={
                    isApproved
                      ? `Korrekturen godkändes ${dayjs(ad.approved_at).format("YYYY-MM-DD")}`
                      : null
                  }
                />
              }
            />
          </Stack>

          <Stack direction="row-reverse" gap={2} sx={{ mt: "auto", pt: 4 }}>
            <Button variant="contained" onClick={handleEdit}>
              Redigera
            </Button>

            <Button onClick={handleOpenDateDialog}>Välj införandedatum</Button>

            <Button onClick={handleDelete}>Ta bort</Button>
          </Stack>
        </Stack>
      </Paper>

      {dateDialogOpen && (
        <PublishDateDialog
          ad={ad}
          key={ad.id}
          open={dateDialogOpen}
          onClose={handleCloseDateDialog}
          onSubmit={handleChangeDate}
        />
      )}
    </>
  );
};

export default memo(AdCard);
