import { useState } from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";

import SubtitledLabel from "@/components/SubtitledLabel";
import { Customer } from "@/extensions/klarahill/types";

interface SendEmailDialogProps {
  mainContact: Customer;
  onChange: (recipient: string) => void;
}

const SendEmailDialog: React.FC<SendEmailDialogProps> = ({
  mainContact,
  onChange,
}) => {
  const [sendToOther, setSendToOther] = useState(false);
  const [email, setEmail] = useState("");

  return (
    <Box sx={{ display: "flex", flexDirection: "column", maxWidth: "500px" }}>
      <FormControl>
        <RadioGroup
          value={sendToOther}
          onChange={(_, value) => {
            if (value === "true") {
              setSendToOther(true);
              onChange(email);
            } else {
              setSendToOther(false);
              onChange(mainContact.email);
            }
          }}
        >
          <FormControlLabel
            value={false}
            control={<Radio />}
            label={
              <SubtitledLabel
                title="Skicka till anhörig"
                subtitle={`${mainContact.first_name} ${mainContact.last_name} <${mainContact.email}>`}
              />
            }
          />
          <FormControlLabel
            value={true}
            control={<Radio />}
            label={
              <Typography variant="subtitle2">
                Skicka till annan mottagare
              </Typography>
            }
          />
        </RadioGroup>
      </FormControl>

      {sendToOther && (
        <Box sx={{ marginLeft: "32px" }}>
          <TextField
            fullWidth
            label="E-post"
            type="email"
            size="small"
            value={email}
            onChange={({ currentTarget: { value: email } }) => {
              setEmail(email);
              onChange(email);
            }}
            helperText="Kom ihåg att gästlistan innehåller persondata om gästerna."
          />
        </Box>
      )}
    </Box>
  );
};

export default SendEmailDialog;
