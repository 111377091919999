import React, { useCallback, useState } from "react";
import {
  Box,
  Button,
  Card as MuiCard,
  CardActions,
  CardContent,
  CardHeader as MuiCardHeader,
  CircularProgress,
  Switch,
  Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import * as Bananas from "bananas-commerce-admin";

import { Card } from "@/extensions/klarahill/components/Card";
import { Case, Cause, PaginationResult } from "@/extensions/klarahill/types";
import * as styles from "@/extensions/klarahill/pages/case/shared";

import * as format from "@/utils/format";
import * as object from "@/utils/object";

export const CaseDetails: React.FC<styles.CaseTabProps> = ({
  case: initialCase,
}) => {
  const api = Bananas.useApi();
  const { navigate } = Bananas.useRouter();
  const { enqueueSnackbar } = useSnackbar();

  const [c, setCase] = useState(initialCase);
  const { office, deceased, customers, causes, florist } = c;

  const [funeral, setFuneral] = useState(c.funeral);
  const [reception, setReception] = useState(c.reception);
  const [memorial, setMemorial] = useState(c.memorial);

  const pennybridgeCauses = useQuery({
    queryKey: ["pennybridge", "cause", "list"],
    queryFn: async () => {
      const action = api.operations["pennybridge.cause:list"];
      if (!action) throw new Error('Invalid action "pennybridge.cause:list".');

      const params = new URLSearchParams({ page_size: "500" });
      const response = await action.call({ query: params });

      if (response.ok) {
        const data: PaginationResult<Cause> = await response.json();
        return data.results as Cause[];
      } else {
        enqueueSnackbar("Kunde inte hämta gåvoorganisationer.", {
          variant: "error",
        });
        return null;
      }
    },
  });

  const [isDismissed, setIsDismissed] = useState(Boolean(c.dismissed_at));
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { first_name, last_name, birth_date, deceased_date } = deceased ?? {};
  const fullName = `${first_name} ${last_name}`;
  const mainContact = customers?.find(({ is_main_contact }) => is_main_contact);

  /**
   * Create a memorial for the current Case.
   * Will reverse Case dismissal if set.
   */
  const createMemorial = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const form = event.currentTarget;
      const formData = new FormData(form);
      const payed = formData.get("marked_as_paid");

      const action = api.operations["case.case:create-memorial"];
      if (!action)
        throw new Error('Invalid action "case.case:create-memorial".');

      setIsSubmitting(true);

      const response = await action.call({
        params: { case_id: c.id },
        body: object.omitNullish({
          marked_as_paid_at: payed === "on" ? new Date().toISOString() : null,
          // These keys have to be present, even though they are not relevant for the `create` request.
          text: "",
        }),
      });

      if (!response.ok) {
        enqueueSnackbar(
          "Fel vid skapande av Minnesrum. Försök igen eller kontakta support om felet kvarstår.",
          { variant: "error" },
        );
        setIsSubmitting(false);
        throw response;
      }

      // Reverse case dismissal, if applicable
      {
        const action = api.operations["case.case:update"];
        if (!action) throw new Error('Invalid action "case.case:update".');

        const response = await action.call({
          params: { case_id: c.id },
          body: { dismissed_at: null }, // Setting `dismissed_at` to `null` undisimsses
        });

        if (!response.ok) {
          enqueueSnackbar(
            "Fel inträffade vid aktiverande av begravningsärende. Hör av dig till support eller försök igen senare.",
            { variant: "error" },
          );
          setIsSubmitting(false);
          throw response;
        }
      }

      const updatedMemorial: Case["memorial"] = await response.json();
      setMemorial(updatedMemorial);
      setIsSubmitting(false);
      setIsDismissed(false);

      enqueueSnackbar("Minnesrum skapat.");
      navigate(`case.case:retrieve`, {
        params: { case_id: c.id },
        query: { tab: "memorial" },
      });
    },
    [
      api,
      c.id,
      c.dismissed_at,
      enqueueSnackbar,
      navigate,
      object.omitNullish,
      setIsDismissed,
      setMemorial,
    ],
  );

  const dismissMemorial = useCallback(
    async (event: React.PointerEvent) => {
      event.preventDefault();
      const action = api.operations["case.case:update"];
      if (!action) throw new Error('Invalid action "case.case:update".');

      const response = await action.call({
        params: { case_id: c.id },
        body: { dismissed_at: new Date().toISOString() },
      });

      if (response.ok) {
        setIsDismissed(true);
        enqueueSnackbar(
          "Minnesrum avfärdades. Begravningsärendet markeras som arkiverat. ",
        );
      } else {
        enqueueSnackbar(
          "Fel vid avfärdande av Minnesrum. Hör av dig till support eller försök igen senare.",
          { variant: "error" },
        );
        throw response;
      }
    },
    [api, c.id, enqueueSnackbar, setIsDismissed],
  );

  const bitnetSync = useCallback(async () => {
    const action = api.operations["case.case:bit"];
    if (!action) throw new Error('Invalid action "case.case:bit".');

    setIsSubmitting(true);
    const response = await action.call({ params: { case_id: c.id } });
    if (response.ok) {
      const data = await response.json();
      setCase(data);
      enqueueSnackbar("Minnesrum skapat.");
    } else {
      enqueueSnackbar(
        "Fel vid synkade mot Bitnet. Hör av dig till support eller försök igen senare.",
        { variant: "error" },
      );
    }

    setIsSubmitting(false);
  }, [api, c.id, enqueueSnackbar, setIsSubmitting, setCase]);

  return (
    <Box sx={styles.content}>
      {isSubmitting ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={styles.cards}>
          <Box sx={styles.primaryCards}>
            <Card
              title="Begravningsärende"
              layout={[
                [{ name: "Namn", type: "text", value: fullName }],
                [
                  { name: "Född", type: "date", value: birth_date },
                  { name: "Avliden", type: "date", value: deceased_date },
                ],
              ]}
            />

            {mainContact && (
              <Card
                title="Anhörig"
                layout={[
                  [
                    {
                      name: "Namn",
                      type: "text",
                      value:
                        mainContact?.first_name &&
                        `${mainContact?.first_name} ${mainContact?.last_name}`,
                    },
                  ],
                  [
                    { name: "E–post", type: "text", value: mainContact?.email },
                    {
                      name: "Telefon",
                      type: "text",
                      value: format.swedishPhoneNumber(mainContact?.phone),
                    },
                  ],
                ]}
              />
            )}

            <Card
              title="Begravning"
              isEditable
              layout={[
                [
                  {
                    name: "Information",
                    formName: "information",
                    type: "text",
                    multiline: true,
                    value: funeral?.information,
                    helperText:
                      'Fritextfält för information gällande begravningen som visas på minnessidan. Exempelvis: "Begravningsceremonin äger rum i Kungsholms kyrka, valfri klädsel."',
                    minRows: 2,
                  },
                ],
                [
                  {
                    name: "Plats",
                    type: "text",
                    value: funeral?.location_name,
                    disabled: true,
                    helperText:
                      "Observera att plats inte visas på minnessidan. Skriv detta i informationsfältet om det ska visas.",
                  },
                  {
                    name: "Datum",
                    type: "date",
                    value: funeral?.funeral_at,
                    disabled: true,
                  },
                ],
                [
                  {
                    name: "Adress",
                    type: "text",
                    value: funeral?.address,
                    disabled: true,
                  },
                  {
                    name: "Stad",
                    type: "text",
                    value:
                      funeral?.city && `${funeral?.zip_code} ${funeral?.city}`,
                    disabled: true,
                  },
                ],
              ]}
              onSubmit={async (values) => {
                const action = api.operations["case.case:update-funeral"];
                if (!action)
                  throw new Error('Invalid action "case.case:update-funeral".');

                const information = values["information"] as string;

                const response = await action.call({
                  params: { case_id: c.id },
                  body: { information },
                });

                if (response.ok) {
                  const updatedFuneral: Case["funeral"] = await response.json();
                  setFuneral(updatedFuneral);
                } else {
                  console.error(response);
                  throw new Error("uppdatering av begravning");
                }

                return "Inställningar uppdaterade.";
              }}
            />

            <Card
              title="Minnesstund"
              isEditable
              layout={[
                [
                  {
                    name: "Syns i minnesrummet",
                    formName: "visibility",
                    type: "switch",
                    value: reception?.visibility ?? false,
                    helperText:
                      "Visa information om minnesstunden i minnesrummet.",
                  },
                ],
                [
                  {
                    name: "Information",
                    formName: "information",
                    type: "text",
                    multiline: true,
                    value: reception?.information,
                    helperText:
                      'Fritextfält för information gällande minnesstunden som visas på minnessidan. Exempelvis: "Efter akten inbjudes till minnesstund, anmäl senast 12 feb."',
                    minRows: 2,
                  },
                ],
                [
                  {
                    name: "Plats",
                    type: "text",
                    value: reception?.location_name,
                    disabled: true,
                  },
                ],
                [
                  {
                    name: "Max antal gäster",
                    formName: "max_attendees",
                    type: "number",
                    value: reception?.max_attendees,
                    min: 1,
                    helperText:
                      "Max antal gäster som kan anmäla sig till minnesstunden. Lämna tomt för obegränsat antal gäster.",
                  },
                  {
                    name: "Sista anmälningsdatum",
                    formName: "rsvp_date",
                    type: "date",
                    value: reception?.rsvp_date,
                  },
                ],
              ]}
              onSubmit={async (values) => {
                const action = api.operations["case.case:update-reception"];
                if (!action)
                  throw new Error(
                    'Invalid action "case.case:update-reception".',
                  );

                const visibility = values["visibility"] === "on";
                const information = values["information"] as string;
                const rsvp_date = values["rsvp_date"] as string;
                const max_attendees =
                  values["max_attendees"] === ""
                    ? undefined
                    : parseInt(values["max_attendees"] as string);

                const response = await action.call({
                  params: { case_id: c.id },
                  body: { visibility, information, rsvp_date, max_attendees },
                });

                if (response.ok) {
                  const updatedReception: Case["reception"] =
                    await response.json();
                  setReception(updatedReception);
                } else {
                  console.error(response);
                  throw new Error("uppdatering av minnesstund");
                }

                return "Inställningar uppdaterade.";
              }}
            />

            <Box sx={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <Typography sx={{ color: "grey.600" }} variant="body2">
                Information om begravningsärendet hämtas från Bitnet. Gå till
                Bitnet för att kunna ändra något av datan. Du måste hämta ny
                data från Bitnet om du ändrar något efter att ärendet har
                skapats.
              </Typography>

              <Box>
                <Button variant="outlined" size="small" onClick={bitnetSync}>
                  Hämta&nbsp;data&nbsp;från&nbsp;Bitnet
                </Button>
              </Box>
            </Box>
          </Box>

          {/* Memorial creation/dismissal features a specialized `MuiCard`. */}
          <Box sx={styles.secondaryCards}>
            <Card
              isCompact
              title="Ärende"
              layout={[
                [
                  {
                    name: "Ärendestatus",
                    type: "text",
                    value: isDismissed ? "Arkiverat" : "Aktuellt",
                  },
                ],
                [{ name: "Byrå", type: "text", value: office?.name }],
                [{ name: "Rådgivare", type: "text", value: c?.advisor }],
              ]}
            />

            {memorial ? (
              <Card
                isCompact
                title="Minnesrum"
                layout={[
                  [
                    {
                      name: "Betalt",
                      type: "text",
                      value: memorial.marked_as_paid_at ? "Ja" : "Nej",
                    },
                  ],
                  [
                    {
                      name: "Publicerat",
                      type: "text",
                      value: memorial.published_at ? "Ja" : "Nej",
                    },
                  ],
                  [
                    {
                      name: "Blomsteraffär oppen",
                      type: "text",
                      value: memorial.purchases_possible ? "Ja" : "Nej",
                    },
                  ],
                  [
                    {
                      name: "Blomsterleverantör",
                      type: "text",
                      value: florist?.name,
                    },
                  ],
                ]}
              />
            ) : (
              <MuiCard>
                <MuiCardHeader
                  sx={{ paddingBottom: 0 }}
                  title={
                    <Typography sx={{ fontWeight: 500 }} variant="body1">
                      Minnesrum
                    </Typography>
                  }
                />
                <Box component={"form"} onSubmit={createMemorial}>
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                      paddingTop: 1,
                    }}
                  >
                    <Typography variant="body2">
                      {isDismissed
                        ? "Det här begravningsärendets minnesrum har avfärdats och ärendet är arkiverat. Du kan ångra detta genom att skapa ett minnesrum, vilket gör ärendet aktuellt."
                        : "Skapa ett minnesrum för begravningsärendet. Minnesrummet måste manuellt publiceras för att det ska bli synligt på hemsidan."}
                    </Typography>

                    {!isDismissed && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="subtitle2">
                          Betalt minnesrum
                        </Typography>
                        <Switch
                          size="medium"
                          name="marked_as_paid"
                          id="marked_as_paid"
                        />
                      </Box>
                    )}
                  </CardContent>

                  <CardActions
                    sx={{
                      padding: [1, 2, 3, 2],
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    {!isDismissed && (
                      <Button
                        variant="outlined"
                        size="medium"
                        onPointerDown={dismissMemorial}
                      >
                        Avfärda
                      </Button>
                    )}

                    <Button
                      variant={isDismissed ? "outlined" : "contained"}
                      size="medium"
                      type="submit"
                    >
                      Skapa
                    </Button>
                  </CardActions>
                </Box>
              </MuiCard>
            )}

            <Card
              isCompact
              isEditable
              title="Minnesgåva"
              layout={[
                [
                  {
                    name: "Mottagare",
                    type: "autocomplete",
                    value:
                      causes?.map(({ name, id }) => ({ label: name, id })) ??
                      [],
                    options:
                      pennybridgeCauses.data?.map(({ name, id }) => ({
                        label: name,
                        id,
                      })) ?? [],
                  },
                ],
              ]}
              onSubmit={async (_, __, autocompleteState) => {
                if (!autocompleteState) return "Ingen förändring av mottagre.";
                const causes = autocompleteState["Mottagare"];
                if (!causes) return "Ingen förändring av mottagre.";

                const action = api.operations["case.case:update"];
                if (!action)
                  throw new Error('Invalid action "case.case:update".');

                const response = await action.call({
                  params: { case_id: c.id },
                  body: { cause_ids: causes?.map(({ id }) => id) },
                });

                if (response.ok) {
                  const data: Case = await response.json();
                  setCase(data);
                  return "Minnesgåvomottagare ändrade.";
                } else {
                  throw response;
                }
              }}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};
