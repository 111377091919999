import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import CheckIcon from "@mui/icons-material/Check";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import WarningIcon from "@mui/icons-material/Warning";

import { AdStatus } from "@/extensions/obituary/types";
import Badge from "@/extensions/klarahill/components/Badge";

const statusIcon: Record<AdStatus, React.FC<{ fontSize: "small" }>> = {
  draft: CreateOutlinedIcon,
  approved: ThumbUpAltIcon,
  pending: NewspaperIcon,
  confirmed: CheckIcon,
  rejected: WarningIcon,
};

const statusColor: Record<AdStatus, Readonly<[string, string]>> = {
  draft: ["#BBDEFB", "#1976D2"],
  approved: ["#C8E6C9", "#1B5E20"],
  pending: ["#E5E5E5", "#525252"],
  confirmed: ["#C8E6C9", "#1B5E20"],
  rejected: ["#FFE0B2", "#E65100"],
};

const statusLabel: Record<AdStatus, string> = {
  draft: "Utkast",
  approved: "Godkänd av kund",
  pending: "Behandlas av publikation",
  confirmed: "Bokad i publikation",
  rejected: "Problem",
};

export interface GiftStatusBadgeProps {
  type: AdStatus;
}

const AdStatusBadge: React.FC<GiftStatusBadgeProps> = ({ type }) => {
  const [background, foreground] = statusColor[type];
  const label = statusLabel[type];
  const Icon = statusIcon[type];

  return (
    <Badge
      label={label}
      backgroundColor={background}
      foregroundColor={foreground}
      icon={<Icon fontSize="small" />}
    />
  );
};

export default AdStatusBadge;
