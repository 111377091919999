import { SxProps } from "@mui/system";
import { Case } from "@/extensions/klarahill/types";

export interface CaseTabProps {
  case: Case;
}

export const content: SxProps = {
  minWidth: 600,
  overflow: "scroll",
  height: "100%",
  flexGrow: 1,
  justifyContent: "flex-start",
  alignItems: "stretch",
  bgcolor: "grey.100",
};

export const cards: SxProps = {
  display: "flex",
  minHeight: "calc(100% + 160px)",
  width: "100%",
  maxWidth: 1300,
  gap: 4,
  flex: 1,
  p: [6, 4],
  pb: 12,
  m: "0 auto",
  "@media (max-width: 900px)": {
    flexDirection: "column-reverse",
  },
};

export const primaryCards: SxProps = {
  flex: 2,
  display: "flex",
  flexDirection: "column",
  gap: 4,
};

export const secondaryCards: SxProps = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  gap: 4,
};

export const rowContainer: SxProps = {
  display: "flex",
  flexDirection: "column",
  maxWidth: 1300,
  width: "100%",
  gap: 4,
  flex: 1,
  margin: "0 auto",
};

export const actionBar: SxProps = {
  // Align with /me in the sidebar.
  height: 72.5,
  justifyContent: "center",
  py: 2,
  gap: 2,
};
