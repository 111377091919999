import { useCallback, useRef, useState } from "react";
import {
  Box,
  CircularProgress,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  Typography,
  SxProps,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import * as Bananas from "bananas-commerce-admin";

import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";

import ErrorCard from "@/extensions/klarahill/components/ErrorCard";
import { MemorialStatusBadge } from "@/extensions/klarahill/components/MemorialStatusBadge";
import SearchBar from "@/extensions/klarahill/components/SearchBar";
import { ListCase, PaginationResult } from "@/extensions/klarahill/types";
import * as styles from "@/extensions/klarahill/pages/case/shared";

import { dateStringOrNull } from "@/utils/dateStringOrNull";
import * as object from "@/utils/object";

const memorialStatusText: Record<ListCase["memorial_status"], string> = {
  not_created: "Ej skapat",
  draft: "Utkast", // TODO: Make sure we have a consistent terminology.
  published: "Publicerat",
} as const;

const CaseRow = ({ case: c }: { case: ListCase }) => {
  const { navigate } = Bananas.useRouter();

  return (
    <TableRow
      key={c.id}
      onClick={() =>
        navigate(`case.case:retrieve`, { params: { case_id: c.id } })
      }
      sx={{ "&:hover": { backgroundColor: "grey.100", cursor: "pointer" } }}
    >
      <TableCell sx={{ paddingLeft: 4 }}>
        <MemorialStatusBadge type={c.is_active ? "active" : "archived"} />
      </TableCell>

      <TableCell>
        <Typography variant="body2">{c.name}</Typography>
      </TableCell>

      <TableCell>
        <Typography variant="body2">{c.advisor}</Typography>
      </TableCell>

      <TableCell>
        <Typography variant="body2">
          {memorialStatusText[c.memorial_status]}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography variant="body2">
          {dateStringOrNull(c.memorial_publish_date)}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography variant="body2">
          {dateStringOrNull(c.date_created)}
        </Typography>
      </TableCell>

      <TableCell sx={{ paddingRight: 3 }} align="right">
        <Typography variant="body2">{c.id}</Typography>
      </TableCell>
    </TableRow>
  );
};

const tabCSS: SxProps = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 1,
  "& svg": {
    transform: "translateY(2px)",
    color: "currentColor",
  },
};

type PanelFilter = "active" | "archived" | "all";

const CasesListPage: Bananas.types.PageComponent<
  PaginationResult<ListCase>
> = ({ data }) => {
  const api = Bananas.useApi();
  const { enqueueSnackbar } = useSnackbar();

  const [{ results, count }, setCases] = useState(data);

  const searchParams = new URLSearchParams(location.search);
  const [searchInput, setSearchInput] = useState(
    searchParams.get("search") ?? "",
  );
  const [pageSize, setPageSize] = useState(
    Number(searchParams.get("page_size") ?? 100),
  );
  const [pageNumber, setPageNumber] = useState(
    Number(searchParams.get("page") ?? 0),
  );

  const onlyMine = useRef((searchParams.get("only_mine") ?? "true") === "true");

  const panelFilter = useRef<PanelFilter>(
    (searchParams.get("panel_filter") ?? "active") as PanelFilter,
  );

  const [initial, setInitial] = useState(true);

  const { error, isFetching, refetch } = useQuery({
    queryKey: [
      "case",
      "list",
      onlyMine.current ?? false,
      panelFilter.current ?? "active",
      pageNumber,
      pageSize,
      searchInput,
    ],
    queryFn: async ({ signal }) => {
      if (initial) {
        setInitial(false);
        return data;
      }

      const action = api.operations["case.case:list"];
      if (!action) throw new Error('No action found for "case.case:list"');

      const params = new URLSearchParams(
        object.omitNullish({
          only_mine: String(onlyMine.current),
          page: String(pageNumber),
          page_size: String(pageSize),
          panel_filter: panelFilter.current,
          search: searchInput.length !== 0 ? searchInput : null,
        }),
      );

      const response = await action.call({ query: params });

      if (signal?.aborted) throw new Error("Query aborted.");

      if (response.ok) {
        history.replaceState(null, "", `?${String(params)}`);

        const newCases: PaginationResult<ListCase> = await response.json();
        setCases(newCases);
        return newCases;
      } else {
        enqueueSnackbar(
          "Fel vid hämtande av begravningsärenden. Försök igen eller kontakta support om felet kvarstår.",
          { variant: "error" },
        );
        throw response;
      }
    },
  });

  const handleSearchBarSubmit = useCallback((query: string) => {
    setSearchInput(query);
  }, []);

  return (
    <>
      <Bananas.TitleBar title="Begravningsärenden">
        <Typography color="grey.600" variant="body2" component="label">
          Visa enbart mina ärenden
        </Typography>

        <Switch
          checked={onlyMine.current}
          onChange={() => {
            onlyMine.current = !onlyMine.current;
            refetch();
          }}
        />
      </Bananas.TitleBar>

      <Box
        component="form"
        onSubmit={(event) => {
          event.preventDefault();
          refetch();
        }}
        sx={{ borderBottom: 1, borderColor: "divider" }}
      >
        <SearchBar
          defaultValue={searchInput}
          onSubmit={handleSearchBarSubmit}
          placeholder="Sök på namn"
        />
      </Box>

      <Box sx={{ borderBottom: 1, borderColor: "divider", px: 3 }}>
        <Tabs
          value={panelFilter.current}
          onChange={(event, tab) => {
            panelFilter.current = tab;
            event.preventDefault();
            refetch();
          }}
          aria-label="Kontrollera vilka begravningsärendent som syns."
        >
          <Tab
            sx={tabCSS}
            label="Aktuella"
            value="active"
            icon={<CreateOutlinedIcon />}
          />
          <Tab
            sx={tabCSS}
            label="Arkiverade"
            value="archived"
            icon={<Inventory2OutlinedIcon />}
          />
          <Tab
            sx={tabCSS}
            label="Alla"
            value="all"
            icon={<GridViewOutlinedIcon />}
          />
        </Tabs>
      </Box>

      <Box
        sx={{
          position: "relative",
          minWidth: 600,
          overflow: "scroll",
          height: "100%",
          flexGrow: 1,
          justifyContent: "flex-start",
          alignItems: "stretch",
        }}
      >
        {isFetching ? (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <CircularProgress />
          </Box>
        ) : count !== 0 ? (
          <>
            <TableContainer component={Box}>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography
                        sx={{ fontWeight: 500, paddingLeft: 2.5 }}
                        variant="body2"
                      >
                        Typ
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <Typography sx={{ fontWeight: 500 }} variant="body2">
                        Namn
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <Typography sx={{ fontWeight: 500 }} variant="body2">
                        Rådgivare
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <Typography sx={{ fontWeight: 500 }} variant="body2">
                        Minnesrum
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <Typography sx={{ fontWeight: 500 }} variant="body2">
                        Publicerat
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <Typography sx={{ fontWeight: 500 }} variant="body2">
                        Skapat
                      </Typography>
                    </TableCell>

                    <TableCell
                      sx={{ fontWeight: 500, paddingRight: 3 }}
                      align="right"
                    >
                      #
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {results?.map((c) => <CaseRow case={c} key={c.id} />)}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              component={Box}
              count={count}
              rowsPerPageOptions={[50, 100, 200]}
              rowsPerPage={pageSize}
              page={pageNumber}
              onPageChange={(_, newPage: number) => {
                setPageNumber(newPage);
                refetch();
              }}
              onRowsPerPageChange={({ target: { value: pageSize } }) => {
                setPageSize(Number(pageSize));
                setPageNumber(0);
                refetch();
              }}
            />
          </>
        ) : (
          <Box sx={styles.content}>
            <Box sx={{ ...styles.cards }}>
              <ErrorCard
                errorMessage={
                  error
                    ? String(error)
                    : count === 0
                      ? "Hittade inga begravningsärenden för ditt urval."
                      : "Det gick inte att hämta några begravningsärenden. Antingen så saknar din organisation ärenden, eller så har något blivit fel. Kontakta support om felet kvarstår."
                }
              />
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default CasesListPage;
