import { useQuery } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import * as Bananas from "bananas-commerce-admin";

import * as object from "@/utils/object";

import {
  ObituarySymbolFilter,
  OBITUARY_SYMBOLS_PAGE_SIZE,
  SymbolListResponse,
} from "@/extensions/obituary/types";

const useImagesQuery = (filter: ObituarySymbolFilter = {}) => {
  const api = Bananas.useApi();
  const { enqueueSnackbar } = useSnackbar();

  const symbols = useQuery({
    queryKey: ["obituary", "symbols", "list", filter],
    queryFn: async () => {
      const action = api.operations["obituary.symbol:list"];
      if (!action) throw new Error('Invalid action "obituary.symbol:list".');

      const belowThreshold = filter.search != null && filter.search.length < 3;
      if (belowThreshold) filter.search = undefined;

      const response = await action.call({
        query: object.omitNullish({
          page_size: OBITUARY_SYMBOLS_PAGE_SIZE,
          ...filter,
        }),
      });

      if (response.ok) {
        const symbols: SymbolListResponse = await response.json();
        return symbols;
      } else {
        enqueueSnackbar("Kunde inte hämta symbolser.", {
          variant: "error",
        });
        console.error(response.statusText);
        return null;
      }
    },
  });

  return symbols;
};

export default useImagesQuery;
