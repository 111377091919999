import { memo, useCallback } from "react";
import { Box, Paper, Stack, SxProps, Theme } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import * as Bananas from "bananas-commerce-admin";

import LabeledSpinner from "@/components/LabeledSpinner";

import ErrorCard from "@/extensions/klarahill/components/ErrorCard";
import useCaseQuery from "@/extensions/klarahill/queries/caseQuery";

import CreateAd from "@/extensions/obituary/components/CreateAdDialog/CreateAd";
import { ObituaryDialogContextProvider } from "@/extensions/obituary/contexts/ObituaryDialogContext";
import Obituary from "@/extensions/obituary/pages/detail";
import { Ad } from "@/extensions/obituary/types";

import Actions from "./Actions";
import AdCard from "./AdCard";

const styles: Record<string, SxProps<Theme>> = {
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    bgcolor: "grey.100",
    minHeight: "100%",
    p: 4,
    pb: 28,

    // make the container scrollable
    overflowY: "scroll",
    overflowX: "hidden",
    maxHeight: "100%",
    WebkitOverflowScrolling: "touch",
    overscrollBehavior: "contain",
  },

  createAd: {
    maxWidth: 600,
  },

  ads: {
    px: 4,
    maxWidth: 1300,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
};

const ObituaryList: Bananas.types.ContribComponent<Ad[]> = ({
  data: ads,
  // @ts-expect-error - ignore
  query: { case_id },
  refresh,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const c = useCaseQuery(case_id);

  const hasAds = Array.isArray(ads) && ads.length > 0;
  const adId = searchParams.get("adId");

  const handleDeleteAd = useCallback(() => refresh(), []);
  const handleChange = useCallback(() => refresh(), []);

  const handleEditAd = useCallback((ad: Ad) => {
    const adId = ad.id.toString();
    setSearchParams({ tab: "obituary.ad:list", adId });
  }, []);

  return c.isPending ? (
    <LabeledSpinner label="Laddar annonser…" sx={{ minHeight: "100%" }} />
  ) : c.isError ? (
    <ErrorCard errorMessage="Ett fel inträffade, kunde inte hämta begravningsannons. Försök igen senare eller kontakta support om problemet kvarstår." />
  ) : adId != null ? (
    <Obituary query={{ case_id, ad_id: Number.parseInt(adId) }} />
  ) : !hasAds ? (
    <Box sx={styles.root}>
      <Paper sx={styles.createAd}>
        <CreateAd ads={[]} c={c.data!} onSubmit={handleEditAd} />
      </Paper>
    </Box>
  ) : (
    <ObituaryDialogContextProvider
      ads={ads}
      c={c.data!}
      onDialogSuccess={handleChange}
      onAdCreate={handleEditAd}
    >
      <Box sx={styles.root}>
        <Stack gap={4} sx={styles.ads}>
          {ads.map((ad) => (
            <AdCard
              key={ad.id}
              ad={ad}
              onApprove={handleChange}
              onChangeDate={handleChange}
              onDeleted={handleDeleteAd}
              onEdit={handleEditAd}
            />
          ))}
        </Stack>
      </Box>

      <Actions />
    </ObituaryDialogContextProvider>
  );
};

export default memo(ObituaryList);
