import { Box, Typography } from "@mui/material";

const Field = ({
  label,
  value,
  author,
  isImage = false,
}: {
  label: string;
  value: string;
  author?: string;
  isImage?: boolean;
}) => (
  <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
    <Typography variant="subtitle2" sx={{ color: "grey.600" }}>
      {label}
    </Typography>

    {isImage ? (
      <Box
        sx={{
          "> img": {
            width: "auto",
            height: "auto",
            maxHeight: "300px",
            objectFit: "cover",
          },
        }}
      >
        <img src={value} alt={`Bild uppladdad av ${author}.`} />
      </Box>
    ) : (
      <Typography variant="body1">{value}</Typography>
    )}
  </Box>
);

export default Field;
