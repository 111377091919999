import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import * as Bananas from "bananas-commerce-admin";

import ErrorCard from "@/extensions/klarahill/components/ErrorCard";
import { OrderRow } from "@/extensions/klarahill/components/OrderRow";
import { Case, Order, PaginationResult } from "@/extensions/klarahill/types";
import * as styles from "@/extensions/klarahill/pages/case/shared";

const ERROR_MESSAGE =
  "Fel vid hämtande av ordrar. Försök igen eller kontakta support om felet kvarstår.";

export const Orders = ({ case: { id } }: { case: Case }) => {
  const api = Bananas.useApi();
  const { enqueueSnackbar } = useSnackbar();

  const orders = useQuery({
    queryKey: ["case", "c", id, "orders"],
    queryFn: async ({ signal }) => {
      const action = api.operations["order.contrib:list-case-orders"];
      if (!action)
        throw new Error('No action found for "order.contrib:list-case-orders"');

      const response = await action.call({
        params: { case_id: id },
      });

      if (signal?.aborted) throw new Error("Query aborted.");

      if (response.ok) {
        const newOrders: PaginationResult<Order> = await response.json();
        return newOrders;
      } else {
        enqueueSnackbar(ERROR_MESSAGE, { variant: "error" });
        throw response;
      }
    },
  });

  if (orders.error) {
    enqueueSnackbar(ERROR_MESSAGE, { variant: "error" });
    throw orders.error;
  }

  const noOrders = orders.data != null && orders.data.count === 0;

  return orders.isFetching ? (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <CircularProgress />
    </Box>
  ) : noOrders ? (
    <Box sx={styles.content}>
      <Box sx={{ ...styles.cards }}>
        <ErrorCard errorMessage="Det finns inga blomsterordrar för det här begravningsärendet." />
      </Box>
    </Box>
  ) : (
    <Box sx={styles.content}>
      <Box sx={styles.cards}>
        <Grid item xs={12} sx={{ width: "100%" }}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography
                      sx={{ fontWeight: 500, paddingLeft: "5px" }}
                      variant="body2"
                    >
                      Typ
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Typography sx={{ fontWeight: 500 }} variant="body2">
                      Namn
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Typography sx={{ fontWeight: 500 }} variant="body2">
                      Epost
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Typography sx={{ fontWeight: 500 }} variant="body2">
                      Begravningsärende
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Typography sx={{ fontWeight: 500 }} variant="body2">
                      Bekräftat den
                    </Typography>
                  </TableCell>

                  <TableCell align="right">
                    <Typography sx={{ fontWeight: 500 }} variant="body2">
                      Ordernummer
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {orders.data?.results?.map((order, i) => (
                  <OrderRow key={i} order={order} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Box>
    </Box>
  );
};
