import { memo, useCallback, useMemo } from "react";
import { Box, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import type {
  Editable,
  Item,
  Story,
} from "@/extensions/obituary/pages/detail/Story/shared";
import { Option } from "@/utils/types";

import { useStory } from "../context";
import { Edit as StoryEdit } from "../index";
import EditableContainer from "./EditableContainer";
import { Singleline } from "./Singleline";

export interface Container extends Item {
  type: "container";
  gap?: boolean;
  flex?: "column" | "row";
  variant:
    | "deceased"
    | "divider"
    | "anthology"
    | "relatives"
    | "relatives-row"
    | "ending"
    | "header";
  children: Story;
}

export function isContainer(item: Item): item is Container {
  return item.type === "container";
}

export const variants: Option[] = [
  { value: "header", label: "Avliden" },
  { value: "deceased", label: "Datum" },
  { value: "relatives", label: "Sörjande" },
  { value: "anthology", label: "Vers" },
  { value: "ending", label: "Avslut" },
  { value: "divider", label: "Streck" },
];

export function isVariant(value: unknown): value is Container["variant"] {
  return variants.some((option) => option.value === value);
}

const Edit: Editable<Container> = ({ item: { children, variant }, path }) => {
  const { dispatch } = useStory();

  const title = useMemo(() => {
    const title = variants.find((option) => option.value === variant)?.label;
    return title ?? "Container";
  }, [variant]);

  const isRelatives = variant === "relatives";

  const handleAdd = useCallback(() => {
    dispatch({
      type: "add",
      direction: "down",
      path: [...path, "children", children.length - 1],
      payload: {
        type: "singleline",
        id: crypto.randomUUID(),
        variant: "default",
        text: "",
      } as Singleline,
    });
  }, [path, children]);

  return (
    <EditableContainer title={title} variant={variant} path={path}>
      <StoryEdit story={children} parentPath={[...path, "children"]} />

      {isRelatives && (
        <Box sx={{ display: "flex", px: 2, pt: 1 }}>
          <Button
            fullWidth
            startIcon={<AddIcon />}
            sx={{ justifyContent: "flex-start" }}
            onClick={handleAdd}
          >
            Lägg till sörjande
          </Button>
        </Box>
      )}
    </EditableContainer>
  );
};

export default memo(Edit);
