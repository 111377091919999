import { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Switch,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSnackbar } from "notistack";
import * as Bananas from "bananas-commerce-admin";

import type { Message as TMessage } from "@/extensions/klarahill/types";

import Comments from "./Comments";
import Field from "./Field";

export interface MessageProps {
  initialMessage: TMessage;
}

const Message: React.FC<MessageProps> = ({ initialMessage }) => {
  const api = Bananas.useApi();
  const { enqueueSnackbar } = useSnackbar();

  const [message, setMessage] = useState(initialMessage);
  const { text, author, date_created, image, id, status, comments } = message;
  const hasComments = Array.isArray(comments) && comments.length > 0;

  const date = new Date(date_created);

  const updateStatus = async () => {
    const action = api.operations["case.case:set-message-status"];
    if (!action)
      throw new Error('Invalid action "case.case:set-message-status".');

    const response = await action.call({
      params: { message_id: id },
      body: { status: status === "published" ? "removed" : "published" },
    });

    if (response.ok) {
      enqueueSnackbar("Status på minne uppdaterat.");
      const updatedMessage: TMessage = await response.json();
      // HACK: Doesn't seem like the response includes the comments, so we just keep the old ones.
      updatedMessage.comments = message.comments;
      setMessage(updatedMessage);
    } else {
      enqueueSnackbar("Fel vid uppdaterande av meddelandestatus.", {
        variant: "error",
      });
      throw response;
    }
  };

  return (
    <Accordion>
      <AccordionSummary sx={{ py: 1, px: 3 }} expandIcon={<ExpandMoreIcon />}>
        <Typography sx={{ marginY: "auto", flexGrow: 1 }}>
          {author}, text {image ? "och bild" : ""}
        </Typography>
      </AccordionSummary>

      <AccordionDetails sx={{ p: 0 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 3,
            px: 3,
            pb: 3,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: 3,
            }}
          >
            <Field label="Namn" value={author} />
            <Box>
              <Typography
                variant="body1"
                component="label"
                htmlFor="visibilitySwitch"
              >
                Synlig
              </Typography>
              <Switch
                checked={status === "published"}
                onChange={updateStatus}
                name="visibilitySwitch"
              />
            </Box>
          </Box>
          <Field label="Datum" value={date.toLocaleDateString()} />
          <Field label="Text" value={text} />
          {image && (
            <Field isImage label="Bild" value={image} author={author} />
          )}
        </Box>

        {hasComments && <Comments comments={comments} />}
      </AccordionDetails>
    </Accordion>
  );
};

export default Message;
