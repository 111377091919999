import { memo, useCallback, useMemo, useState } from "react";
import { Stack, Paper, PaperProps, Typography, SxProps } from "@mui/material";

import { Poem } from "@/extensions/obituary/types";

export interface PoemCardProps extends Omit<PaperProps, "onClick"> {
  selected?: boolean;
  poem: Poem;
  onClick: (symbol: Poem) => void;
}

const styles: Record<string, SxProps> = {
  paper: {
    cursor: "pointer",
    "&:hover": { borderColor: "primary.main" },
  },

  selected: {
    outline: "1px solid",
    outlineColor: "primary.main",
    borderColor: "primary.main",
  },

  root: {
    height: 180,
    width: "100%",
    overflow: "hidden",
  },

  expandedRoot: {
    minHeight: 180,
    height: "100% !important",
  },

  poem: {
    m: 1.5,
    height: "100%",
    maxHeight: 124,
  },

  expandedPoem: {
    maxHeight: "none",
  },

  text: {
    fontSize: 14,
    whiteSpace: "pre-wrap",
  },

  footer: {
    p: 1,
    bgcolor: "grey.50",
    borderRadius: "0 0 4px 4px",
    height: "100%",
    maxHeight: 36,
    mt: "auto",
    borderTop: "1px solid",
    borderColor: "grey.300",
  },

  title: {
    fontSize: 13,
  },
};

const PoemCard: React.FC<PoemCardProps> = ({
  onClick,
  selected,
  poem,
  sx,
  ...props
}) => {
  const [expanded, setExpanded] = useState(false);

  const handleOnClick = useCallback(() => {
    setExpanded(true);
    onClick(poem);
  }, [onClick, poem]);

  const hasAuthor = useMemo(
    () => poem?.author != null && poem.author !== "",
    [poem],
  );

  return (
    <Paper
      elevation={0}
      variant="outlined"
      role="button"
      onClick={handleOnClick}
      // @ts-expect-error
      sx={{
        ...styles.paper,
        ...(selected && styles.selected),
        ...sx,
      }}
      {...props}
    >
      <Stack
        // @ts-expect-error - Incorrect SxProps inference
        sx={{ ...styles.root, ...(expanded && styles.expandedRoot) }}
      >
        <Stack
          component="article"
          // @ts-expect-error - Incorrect SxProps inference.
          sx={{ ...styles.poem, ...(expanded && styles.expandedPoem) }}
        >
          {poem.text.split("\n").map((line, index) => (
            <Typography key={index} sx={styles.text}>
              {line}
            </Typography>
          ))}
        </Stack>

        <Stack sx={styles.footer}>
          <Typography sx={styles.title}>
            {poem?.reference} {hasAuthor && `• ${poem?.author}`}
          </Typography>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default memo(PoemCard);
