import { TableCell, TableRow, Typography } from "@mui/material";

import * as Bananas from "bananas-commerce-admin";

import { dateStringOrNull } from "../../../utils/dateStringOrNull";
import { OrderStatusBadge } from "../components/OrderStatusBadge";
import { Order } from "../types";

export const OrderRow = ({
  order: { name, email, case: c, purchase_number, confirmed_at },
}: {
  order: Order;
}) => {
  const { navigate } = Bananas.useRouter();

  return (
    <TableRow
      onClick={() =>
        navigate(`pos.purchase:detail`, {
          params: { purchase_number: purchase_number },
        })
      }
      sx={{ "&:hover": { backgroundColor: "grey.100", cursor: "pointer" } }}
    >
      <TableCell>
        <OrderStatusBadge type={confirmed_at ? "captured" : "created"} />
      </TableCell>

      <TableCell>
        <Typography variant="body2">{name}</Typography>
      </TableCell>

      <TableCell>
        <Typography variant="body2">{email}</Typography>
      </TableCell>

      <TableCell>
        <Typography variant="body2">{c}</Typography>
      </TableCell>

      <TableCell>
        <Typography variant="body2">
          {dateStringOrNull(confirmed_at)}
        </Typography>
      </TableCell>

      <TableCell sx={{ paddingRight: 3 }} align="right">
        <Typography variant="body2">{purchase_number}</Typography>
      </TableCell>
    </TableRow>
  );
};
