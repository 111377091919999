import { memo } from "react";
import { Button, Stack } from "@mui/material";
import * as Bananas from "bananas-commerce-admin";

import AddIcon from "@mui/icons-material/Add";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import NewspaperIcon from "@mui/icons-material/Newspaper";

import * as caseStyles from "@/extensions/klarahill/pages/case/shared";
import { useObituaryDialog } from "@/extensions/obituary/contexts/ObituaryDialogContext";

const Actions = () => {
  const { openDialog } = useObituaryDialog();

  return (
    <Bananas.ActionBar>
      <Stack direction="row" sx={caseStyles.actionBar}>
        <Button
          variant="outlined"
          startIcon={<MailOutlineIcon />}
          onClick={openDialog?.("proof")}
        >
          Skicka korrektur
        </Button>

        <Button
          variant="outlined"
          startIcon={<NewspaperIcon />}
          onClick={openDialog?.("publish")}
        >
          Boka annonser
        </Button>

        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={openDialog?.("create")}
        >
          Skapa ny annons
        </Button>
      </Stack>
    </Bananas.ActionBar>
  );
};

export default memo(Actions);
