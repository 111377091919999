import { SxProps } from "@mui/material";

import { Nullish } from "@/utils/types";

export interface AutocompleteState {
  label: string;
  id: string | number;
}

export type Field = {
  name: string;
  formName?: string;
  required?: boolean;
  disabled?: boolean;
  options?: AutocompleteState[];
  helperText?: string;
} & (
  | {
      type: "text";
      multiline?: boolean;
      value: Nullish<string>;
      maxLength?: number;
      minRows?: number;
    }
  | { type: "number"; value: Nullish<number>; min?: number; max?: number }
  | { type: "switch"; on?: string; off?: string; value: boolean }
  | { type: "image"; value: Nullish<string> }
  | { type: "obituary"; value: Nullish<string> }
  | { type: "portrait"; value: Nullish<string> }
  | { type: "date"; value: Nullish<string> }
  | { type: "autocomplete"; value: AutocompleteState[] }
);

export const readFieldCSS = (isCompact: boolean): SxProps => ({
  display: "flex",
  gap: 0.5,
  flexDirection: isCompact ? "row" : "column",
  justifyContent: isCompact ? "space-between" : "flex-start",
});

export const imgCSS = {
  width: "auto",
  height: "100%",
  maxHeight: "200px",
  maxWidth: "200px",
  objectFit: "cover",
};
