import { memo, useCallback } from "react";
import {
  Box,
  Divider,
  Stack,
  Paper,
  PaperProps,
  Typography,
  SxProps,
} from "@mui/material";

import {
  ObituarySymbol,
  ObituarySymbolOrImage,
} from "@/extensions/obituary/types";

export interface ImageCardProps extends Omit<PaperProps, "onClick"> {
  selected?: boolean;
  symbol: ObituarySymbolOrImage;
  onClick: (symbol: ObituarySymbolOrImage) => void;
}

const styles: Record<string, SxProps> = {
  paper: {
    cursor: "pointer",
    "&:hover": { borderColor: "primary.main" },
  },

  category: {
    fontSize: 12,
    color: "text.secondary",
  },

  selected: {
    outline: "1px solid",
    outlineColor: "primary.main",
    borderColor: "primary.main",
  },

  root: {
    height: 300,
    width: "100%",
    overflow: "hidden",
  },

  header: {
    p: 1,
    bgcolor: "grey.50",
    borderRadius: "0 0 4px 4px",
    height: "100%",
    maxHeight: 82,
  },

  title: {
    fontSize: 13,
  },

  figure: {
    m: 3,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    maxHeight: 170,
  },

  img: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
};

function isObituarySymbol(
  symbol: ObituarySymbolOrImage,
): symbol is ObituarySymbol {
  return "file_url" in symbol;
}

const ImageCard: React.FC<ImageCardProps> = ({
  onClick,
  selected,
  symbol,
  sx,
  ...props
}) => {
  const isSymbol = isObituarySymbol(symbol);

  const handleOnClick = useCallback(() => {
    onClick?.(symbol);
  }, [onClick, symbol]);

  return (
    <Paper
      elevation={0}
      variant="outlined"
      role="button"
      onClick={handleOnClick}
      sx={
        { ...styles.paper, ...(selected && styles.selected), ...sx } as SxProps
      }
      {...props}
    >
      <Stack
        divider={<Divider flexItem />}
        sx={{ ...styles.root, height: isSymbol ? 300 : 220 }}
      >
        <Box component="figure" sx={styles.figure}>
          <Box
            component="img"
            src={isSymbol ? symbol.file_url : symbol.url}
            sx={styles.img}
          />
        </Box>

        {isSymbol && (
          <Stack sx={styles.header}>
            <Typography sx={styles.title}>{symbol?.title}</Typography>
            <Typography sx={styles.category}>
              {symbol?.reference} • {symbol?.category}
            </Typography>
          </Stack>
        )}
      </Stack>
    </Paper>
  );
};

export default memo(ImageCard);
