import { memo, useCallback, useState } from "react";
import {
  Button,
  FormControlLabel,
  Divider,
  Stack,
  SxProps,
  Switch,
} from "@mui/material";
import dayjs from "dayjs";

import AddIcon from "@mui/icons-material/Add";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import EmailIcon from "@mui/icons-material/Email";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import PrintIcon from "@mui/icons-material/Print";

import SubtitledLabel from "@/components/SubtitledLabel";
import { Nullish, Timedate } from "@/utils/types";

import AdMeta from "@/extensions/obituary/components/AdMeta";
import PublishDateDialog from "@/extensions/obituary/components/PublishDateDialog";
import { useObituaryDialog } from "@/extensions/obituary/contexts/ObituaryDialogContext";
import { Ad, PatchAdPayload } from "@/extensions/obituary/types";

const styles: Record<string, SxProps> = {
  button: {
    justifyContent: "flex-start",
    gap: 0.5,
  },
};

export interface AdPanelProps {
  ad: Ad;
  updateAd: (payload: Partial<PatchAdPayload>) => void;
}

const AdPanel: React.FC<AdPanelProps> = ({ ad, updateAd }) => {
  const [dateDialogOpen, setDateDialogOpen] = useState(false);
  const { openDialog } = useObituaryDialog();

  const isApproved = ad.approved_at != null;

  const handleApprove = useCallback(() => {
    if (isApproved && !confirm("Vill du neka godkännandet?")) return;
    updateAd({ approve: !isApproved });
  }, [isApproved, updateAd]);

  const handleChangeDate = useCallback(
    async (
      publication_date: Nullish<Timedate>,
      editing_deadline: Nullish<Timedate>,
    ) => {
      if (publication_date == null || editing_deadline == null) return;
      updateAd({ deadline: { publication_date, editing_deadline } });
    },
    [updateAd],
  );

  const handleCloseDateDialog = useCallback(() => setDateDialogOpen(false), []);
  const handleOpenDateDialog = useCallback(() => setDateDialogOpen(true), []);

  return (
    <>
      <Stack gap={2}>
        <AdMeta showPublishInfo ad={ad} />

        <Divider flexItem />

        <FormControlLabel
          control={<Switch defaultChecked={isApproved} />}
          onChange={handleApprove}
          sx={styles.approve}
          label={
            <SubtitledLabel
              sx={{ gap: 0.5 }}
              title="Godkänd korrektur"
              subtitle={
                isApproved
                  ? `Korrekturen godkändes ${dayjs(ad.approved_at).format("YYYY-MM-DD")}`
                  : null
              }
            />
          }
        />

        <Divider flexItem />

        <Stack>
          <Button
            fullWidth
            sx={styles.button}
            startIcon={<EditCalendarIcon />}
            onClick={handleOpenDateDialog}
          >
            Välj införandedatum
          </Button>

          <Button
            fullWidth
            sx={styles.button}
            startIcon={<PrintIcon />}
            onClick={() => alert("Not implemented")}
          >
            Skriv ut korrektur
          </Button>
        </Stack>

        <Divider flexItem />

        <Stack>
          <Button
            fullWidth
            sx={styles.button}
            startIcon={<EmailIcon />}
            onClick={openDialog("proof")}
          >
            Skicka korrektur
          </Button>

          <Button
            fullWidth
            sx={styles.button}
            startIcon={<NewspaperIcon />}
            onClick={openDialog("publish")}
          >
            Boka annonser
          </Button>

          <Button
            fullWidth
            sx={styles.button}
            startIcon={<AddIcon />}
            onClick={openDialog("create")}
          >
            Skapa ny annons
          </Button>
        </Stack>
      </Stack>

      {dateDialogOpen && (
        <PublishDateDialog
          ad={ad}
          key={ad.id}
          open={dateDialogOpen}
          onClose={handleCloseDateDialog}
          onSubmit={handleChangeDate}
        />
      )}
    </>
  );
};

export default memo(AdPanel);
