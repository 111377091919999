import { memo } from "react";
import { Stack, Typography } from "@mui/material";

import { readFieldCSS } from "@/extensions/klarahill/components/Card/shared";

export interface LabeledValueProps {
  label: string;
  value: string;
  compact?: boolean;
  multiline?: boolean;
}

const LabeledValue: React.FC<LabeledValueProps> = ({
  label,
  value,
  compact,
  multiline,
}) => (
  <Stack sx={readFieldCSS(Boolean(compact))}>
    <Typography variant="subtitle2" sx={{ color: "grey.600" }}>
      {label}
    </Typography>

    <Typography
      variant={compact ? "body2" : "body1"}
      sx={{
        whiteSpace: multiline ? "break-spaces" : "unset",
        wordBreak: "break-all",
      }}
    >
      {value}
    </Typography>
  </Stack>
);

export default memo(LabeledValue);
