import React, { memo, useCallback } from "react";
import { Box, Tooltip } from "@mui/material";

import { Emoticon } from "@/extensions/obituary/types";

export interface EmoticonButtonProps extends Emoticon {
  onEmoticonClick: (emoticon: Emoticon) => void;
}

const EmoticonButton: React.FC<EmoticonButtonProps> = ({
  code,
  name,
  char,
  onEmoticonClick,
}) => {
  const handleClick = useCallback(() => {
    onEmoticonClick({ code, char, name });
  }, [code, char, name, onEmoticonClick]);

  return (
    <Tooltip arrow title={name}>
      <Box
        aria-label={`Lägg in ${name}`}
        role="button"
        tabIndex={-1}
        onClick={handleClick}
        sx={{ width: "auto", height: 22, cursor: "pointer", px: 0.75 }}
      >
        {char}
      </Box>
    </Tooltip>
  );
};

export default memo(EmoticonButton);
