import {
  Autocomplete,
  Box,
  FormHelperText,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import FormDatePicker from "@/components/FormDatePicker";
import { AutocompleteState, Field, imgCSS, readFieldCSS } from "./shared";

export type EditFieldProps = {
  isCompact: boolean;
  isDisabled: boolean;
  updateAutoCompleteState: (name: string, value: AutocompleteState[]) => void;
} & Field;

const EditField: React.FC<EditFieldProps> = ({
  type,
  name,
  formName,
  value,
  options,
  isCompact,
  isDisabled,
  required,
  updateAutoCompleteState,
  ...props
}) => {
  const size = isCompact ? "small" : "medium";

  switch (type) {
    case "autocomplete": {
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Autocomplete
            multiple={true}
            id={formName}
            options={options ?? []}
            defaultValue={value}
            sx={{
              width: "100%",
              maxWidth: 400,
              "@media (max-width: 900px)": {
                maxWidth: "100%",
              },
            }}
            isOptionEqualToValue={(
              option: AutocompleteState,
              value: AutocompleteState,
            ) => option.id === value.id}
            onChange={(_, value) =>
              updateAutoCompleteState(name, value as AutocompleteState[])
            }
            renderInput={(props) => <TextField {...props} label={name} />}
          />

          {props.helperText && (
            <FormHelperText>{props.helperText}</FormHelperText>
          )}
        </Box>
      );
    }

    case "switch": {
      return (
        <Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: isCompact ? "space-between" : "start",
            }}
          >
            <Typography variant="subtitle2">{name}</Typography>

            <Switch
              defaultChecked={Boolean(value)}
              size={isCompact ? "small" : "medium"}
              name={formName}
              required={required}
              disabled={isDisabled}
            />
          </Box>

          {props.helperText && (
            <FormHelperText>{props.helperText}</FormHelperText>
          )}
        </Box>
      );
    }

    case "portrait":
    case "image":
      return (
        <Box sx={readFieldCSS(isCompact)}>
          <Typography variant="subtitle2" sx={{ color: "grey.600" }}>
            {name}
          </Typography>

          <input
            name={formName}
            type="file"
            accept="image/*"
            required={required}
            disabled={isDisabled}
          />

          {props.helperText && (
            <FormHelperText>{props.helperText}</FormHelperText>
          )}
        </Box>
      );

    case "obituary": {
      return (
        <Box sx={readFieldCSS(isCompact)}>
          <Typography variant="subtitle2" sx={{ color: "grey.600" }}>
            {name}
          </Typography>

          <Box sx={{ "> img": imgCSS }}>{value && <img src={value} />}</Box>

          <input
            name={formName}
            type="file"
            accept="image/*, application/pdf"
            required={required}
            disabled={isDisabled}
          />

          {props.helperText && (
            <FormHelperText>{props.helperText}</FormHelperText>
          )}
        </Box>
      );
    }

    case "date": {
      return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <FormDatePicker
            disabled={isDisabled}
            name={formName ?? "date"}
            label={name}
            value={value}
          />
          {props.helperText && (
            <FormHelperText>{props.helperText}</FormHelperText>
          )}
        </Box>
      );
    }

    case "text": {
      const isMultiline = ("multiline" in props && props.multiline) ?? false;

      return (
        <TextField
          name={formName}
          defaultValue={value ?? ""}
          size={size}
          label={name}
          variant="outlined"
          required={required}
          disabled={isDisabled}
          multiline={isMultiline}
          helperText={props.helperText}
          minRows={"minRows" in props ? props.minRows : undefined}
          inputProps={{
            maxLength: "maxLength" in props ? props.maxLength : undefined,
          }}
        />
      );
    }

    case "number": {
      return (
        <TextField
          type="number"
          name={formName}
          defaultValue={value ?? ""}
          size={size}
          label={name}
          variant="outlined"
          required={required}
          disabled={isDisabled}
          inputProps={{
            min: "min" in props ? props.min : undefined,
            max: "max" in props ? props.max : undefined,
          }}
          helperText={props.helperText}
        />
      );
    }

    default:
      throw new Error(`Unknown type: ${type}.`);
  }
};

export default EditField;
