import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from "react";
import { Stack, SxProps } from "@mui/material";
import { TabPanel } from "@mui/lab";
import { useSearchParams } from "react-router-dom";

import LabeledSpinner from "@/components/LabeledSpinner";
import { LIST_MAKER } from "@/components/ListTabs";
import TabbedSidebar from "@/components/TabbedSidebar";
import useHash from "@/hooks/useHash";
import { Nullish, Option } from "@/utils/types";

import { Case } from "@/extensions/klarahill/types";
import { ObituaryDialogContextProvider } from "@/extensions/obituary/contexts/ObituaryDialogContext";
import { Ad, UpdateAdPayload } from "@/extensions/obituary/types";
import usePreviewQuery from "@/extensions/obituary/queries/usePreviewQuery";
import adName from "@/extensions/obituary/utils/adName";

import AdPanel from "./AdPanel";
import Canvas from "./Canvas";
import { EditPanel, Story } from "./Story";
import reducer from "./reducer";

const styles: Record<string, SxProps> = {
  root: {
    bgcolor: "grey.100",

    overflowY: "scroll",
    overflowX: "hidden",
    height: "100%",
    maxHeight: "calc(100vh - 118px)",
  },
  sidebar: {
    overflowY: "scroll",
    overflowX: "hidden",
    height: "100%",
    maxHeight: "100%",
    WebkitOverflowScrolling: "touch",
    overscrollBehavior: "contain",
  },
  tabPanel: { p: 0 },
  spinner: { width: "100%", height: "100%" },
};

const sidebarTabs = [
  { label: "Innehåll", value: "editor" },
  { label: "Annons", value: "ad" },
];

export interface ObituaryEditorProps {
  c: Case;
  ad: Ad;
  ads: Ad[];
  setAd: (adId: Ad["id"]) => void;
  updateAd: (data: {
    ad: Nullish<Ad>;
    story: Nullish<Story>;
    payload?: Nullish<Partial<UpdateAdPayload>>;
  }) => void;
}

const ObituaryEditor: React.FC<ObituaryEditorProps> = ({
  c,
  ad,
  ads,
  setAd,
  updateAd,
}) => {
  const [_, setSearchParams] = useSearchParams();

  const [story, dispatch] = useReducer(reducer, ad.story);

  // Use a hash to detect changes in the story, prevent unneccary update changes.
  const storyHash = useHash(story);
  const previousStoryHash = useRef<string>();
  useEffect(() => {
    if (storyHash === previousStoryHash.current) return;
    updateAd({ ad, story });
    previousStoryHash.current = storyHash;
  }, [ad, storyHash]);

  // Get the preview image based on the current story.
  const { preview, lastPreview } = usePreviewQuery(ad.id, storyHash, {
    publication: ad.publication_abbreviation,
    layout: ad.layout_variant,
    story,
  });

  const showCanvas = useMemo(() => {
    return ad != null && preview != null && lastPreview !== "";
  }, [ad, preview]);

  const tabs = useMemo<Option[]>(() => {
    if (!Array.isArray(ads)) return [];
    return ads.map((ad) => ({ label: adName(ad), value: ad.id.toString() }));
  }, [ads]);

  const handleTabChange = useCallback(({ value }: Option) => {
    if (value === LIST_MAKER) {
      setSearchParams({ tab: "obituary.ad:list" });
    } else {
      const newAdId = Number.parseInt(value);
      setAd(newAdId);
    }
  }, []);

  const handleAdPanelUpdateAd = useCallback(
    (payload: Partial<UpdateAdPayload>) => {
      updateAd({ payload, ad, story });
    },
    [ad, story, updateAd],
  );

  const handleAdCreate = useCallback((ad: Ad) => {
    const adId = ad.id.toString();
    setSearchParams({ tab: "obituary.ad:list", adId });
  }, []);

  return (
    <ObituaryDialogContextProvider
      ads={ads}
      c={c}
      key={ad.id}
      onAdCreate={handleAdCreate}
    >
      <Stack direction="row" sx={styles.root}>
        <TabbedSidebar tabs={sidebarTabs}>
          <TabPanel value="editor" sx={styles.tabPanel}>
            <EditPanel
              c={c}
              dispatch={dispatch}
              layout={ad.layout_variant}
              story={story}
            />
          </TabPanel>

          <TabPanel value="ad">
            <AdPanel ad={ad} updateAd={handleAdPanelUpdateAd} />
          </TabPanel>
        </TabbedSidebar>

        {showCanvas ? (
          <Canvas
            adId={ad.id}
            src={lastPreview!}
            tabs={tabs}
            onTabChange={handleTabChange}
            layout={ad.layout_variant}
          />
        ) : (
          <LabeledSpinner
            label="Laddar förhandsvisning..."
            sx={styles.spinner}
          />
        )}
      </Stack>
    </ObituaryDialogContextProvider>
  );
};

export default memo(ObituaryEditor);
