import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import * as Bananas from "bananas-commerce-admin";
import { useSnackbar } from "notistack";
import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";

import ErrorCard from "@/extensions/klarahill/components/ErrorCard";
import { OrderRow } from "@/extensions/klarahill/components/OrderRow";
import SearchBar from "@/extensions/klarahill/components/SearchBar";
import { Order, PaginationResult } from "@/extensions/klarahill/types";

import * as object from "@/utils/object";

const OrderListPage: Bananas.types.PageComponent<PaginationResult<Order>> = ({
  data,
}) => {
  const api = Bananas.useApi();
  const { enqueueSnackbar } = useSnackbar();

  const [{ results, count }, setOrders] = useState(data);

  const searchParams = new URLSearchParams(location.search);
  const [searchInput, setSearchInput] = useState(
    searchParams.get("search") ?? "",
  );
  const [pageSize, setPageSize] = useState(
    Number(searchParams.get("page_size") ?? 100),
  );
  const [pageNumber, setPageNumber] = useState(
    Number(searchParams.get("page") ?? 0),
  );

  const [initial, setInitial] = useState(true);

  const { error, isFetching, refetch } = useQuery({
    queryKey: ["case", "order", "list"],
    queryFn: async ({ signal }) => {
      if (initial) {
        setInitial(false);
        return data;
      }

      const action = api.operations["case.case:list"];
      if (!action) throw new Error('No action found for "case.case:list"');

      const params = new URLSearchParams(
        object.omitNullish({
          page: String(pageNumber),
          page_size: String(pageSize),
          search: searchInput.length !== 0 ? searchInput : null,
        }),
      );

      const response = await action.call({ query: params });

      if (signal?.aborted) throw new Error("Query aborted.");

      if (response.ok) {
        history.replaceState(null, "", `?${String(params)}`);

        const newOrders: PaginationResult<Order> = await response.json();
        setOrders(newOrders);
        return newOrders;
      } else {
        enqueueSnackbar(
          "Fel vid hämtande av ordrar. Försök igen eller kontakta support om felet kvarstår.",
          { variant: "error" },
        );
        throw response;
      }
    },
  });

  return (
    <>
      <Bananas.TitleBar title="Blomsterordrar" />

      <Box
        component="form"
        onSubmit={(event) => {
          event.preventDefault();
          refetch();
        }}
        sx={{ borderBottom: 1, borderColor: "divider" }}
      >
        <SearchBar
          defaultValue={searchInput}
          onChange={setSearchInput}
          placeholder="Sök på namn"
        />
      </Box>

      <Box
        sx={{
          position: "relative",
          minWidth: 600,
          overflow: "scroll",
          height: "100%",
          flexGrow: 1,
          justifyContent: "flex-start",
          alignItems: "stretch",
        }}
      >
        {isFetching ? (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <CircularProgress />
          </Box>
        ) : count !== 0 ? (
          <>
            <TableContainer component={Box}>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography
                        sx={{ fontWeight: 500, paddingLeft: "5px" }}
                        variant="body2"
                      >
                        Typ
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <Typography sx={{ fontWeight: 500 }} variant="body2">
                        Namn
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <Typography sx={{ fontWeight: 500 }} variant="body2">
                        Epost
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <Typography sx={{ fontWeight: 500 }} variant="body2">
                        Begravningsärende
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <Typography sx={{ fontWeight: 500 }} variant="body2">
                        Bekräftat den
                      </Typography>
                    </TableCell>

                    <TableCell align="right">
                      <Typography sx={{ fontWeight: 500 }} variant="body2">
                        Ordernummer
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {results?.map((order, i) => (
                    <OrderRow order={order} key={i} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              component={Box}
              count={count}
              rowsPerPageOptions={[50, 100, 200]}
              rowsPerPage={pageSize}
              page={pageNumber}
              onPageChange={(_, newPage: number) => {
                setPageNumber(newPage);
                refetch();
              }}
              onRowsPerPageChange={({ target: { value: pageSize } }) => {
                setPageSize(Number(pageSize));
                setPageNumber(0);
                refetch();
              }}
            />
          </>
        ) : (
          <ErrorCard
            containerProps={{ sx: { p: 4 } }}
            errorMessage={
              error
                ? String(error)
                : "Det gick inte att hämta några Blomsterordrar. Antingen så saknar din organisation ordrar, eller så har något blivit fel. Kontakta support om felet kvarstår."
            }
          />
        )}
      </Box>
    </>
  );
};

export default OrderListPage;
