import FireplaceOutlinedIcon from "@mui/icons-material/FireplaceOutlined";
import FlowerIcon from "@mui/icons-material/LocalFloristOutlined";
import { OpenAPI } from "openapi-types";
import * as Bananas from "bananas-commerce-admin";

import CasesDetailPage from "./pages/case/Detail";
import CasesListPage from "./pages/case/List";
import OrderListPage from "./pages/order/List";

interface Route {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  page: () =>
    | Bananas.types.PageComponent<any>
    | Promise<Bananas.types.PageComponent<any>>;
  request?: OpenAPI.Request;
  defaultRequest?: OpenAPI.Request;
}

const routes: Record<string, Record<string, Route>> = {
  case: {
    list: {
      page: () => CasesListPage,
      defaultRequest: {
        query: {
          // NOTE: Make sure that these match the default states of page.
          panel_filter: "active",
          only_mine: "true",
        },
      },
    },
    retrieve: { page: () => CasesDetailPage },
  },
  order: {
    list: { page: () => OrderListPage },
    order: { page: () => OrderListPage },
  },
};

export const caseRouter: Bananas.RouterExtension = {
  app: "case",
  pages: (route) => {
    const hit = routes[route.view]?.[route.action];
    return hit?.page != null
      ? {
          page: hit.page(),
          request: hit.request,
          defaultRequest: hit.defaultRequest,
        }
      : undefined;
  },
};

export const orderRouter: Bananas.RouterExtension = {
  app: "order",
  pages: (route) => {
    const hit = routes[route.view]?.[route.action];
    return hit?.page != null
      ? {
          page: hit.page(),
          request: hit.request,
          defaultRequest: hit.defaultRequest,
        }
      : undefined;
  },
};

export const navigation: Bananas.types.NavigationOverrides = {
  "case.case:list": {
    title: "Begravningsärenden",
    icon: FireplaceOutlinedIcon,
  },
  "order.order:list": {
    title: "Blomsterordrar",
    icon: FlowerIcon,
  },
  "pos.purchase:list": false,
} as const;
