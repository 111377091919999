type Reader = ReadableStreamDefaultReader<Uint8Array>;

export const mimeType = {
  svg: "image/svg+xml",
  png: "image/png",
  jpeg: "image/jpeg",
  gif: "image/gif",
  webp: "image/webp",
} as const;

export type Type = keyof typeof mimeType;
export type MimeType = (typeof mimeType)[keyof typeof mimeType];

const decoder = new TextDecoder();

export async function readString(
  reader: Reader,
  result: string = "",
): Promise<string> {
  const { done, value } = await reader.read();
  result += decoder.decode(value);
  return done ? result : await readString(reader, result);
}

export function createDataUrl(data: string, type: MimeType) {
  const blob = new Blob([data], { type });
  return URL.createObjectURL(blob);
}

export async function toDataURL(reader: Reader, type: Type) {
  const data = await readString(reader);
  return createDataUrl(data, mimeType[type]);
}
