import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";

import Badge from "./Badge";

type MemorialStatus = "active" | "archived";

const statusIcon: Record<MemorialStatus, React.FC<{ fontSize: "small" }>> = {
  active: CreateOutlinedIcon,
  archived: Inventory2OutlinedIcon,
} as const;

const statusColor: Record<MemorialStatus, Readonly<[string, string]>> = {
  active: ["#BBDEFB", "#1976D2"],
  archived: ["#E5E5E5", "#525252"],
} as const;

export const MemorialStatusBadge = ({ type }: { type: MemorialStatus }) => {
  const [background, foreground] = statusColor[type];
  const Icon = statusIcon[type];

  return (
    <Badge
      backgroundColor={background}
      foregroundColor={foreground}
      icon={<Icon fontSize="small" />}
    />
  );
};
