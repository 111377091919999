import { memo } from "react";
import { Stack, StackProps, Typography } from "@mui/material";

import { Nullish } from "@/utils/types";

export interface SubtitledLabelProps extends StackProps {
  title: string;
  subtitle?: Nullish<string>;
}

const SubtitledLabel: React.FC<SubtitledLabelProps> = ({
  title,
  subtitle,
  ...props
}) => (
  <Stack {...props}>
    <Typography variant="subtitle2">{title}</Typography>
    {subtitle != null && subtitle !== "" && (
      <Typography
        sx={{ lineHeight: 0.9 }}
        variant="subtitle2"
        color="textSecondary"
        fontWeight={400}
      >
        {subtitle}
      </Typography>
    )}
  </Stack>
);

export default memo(SubtitledLabel);
