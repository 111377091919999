import { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Card as MuiCard,
  CardActions as MuiCardActions,
  CardContent as MuiCardContent,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import * as Bananas from "bananas-commerce-admin";

import CardHeader from "@/extensions/klarahill/components/Card/CardHeader";
import type {
  Obituary as TObituary,
  Memorial,
} from "@/extensions/klarahill/types";
import Obituary from "./Obituary";
import { ObituaryMap } from "./shared";

export interface ObituariesProps {
  memorial: Memorial;
  setMemorial: React.Dispatch<React.SetStateAction<Memorial>>;
}

const Obituaries: React.FC<ObituariesProps> = ({ memorial, setMemorial }) => {
  const api = Bananas.useApi();

  const [isEditing, setIsEditing] = useState(false);
  const [changed, setChanged] = useState<ObituaryMap>({});
  const [created, setCreated] = useState<ObituaryMap>({});

  const { enqueueSnackbar } = useSnackbar();

  const addObituary = useCallback(() => {
    setCreated((prev) => {
      const id =
        Math.max(...Object.keys(prev).map((id) => Number.parseInt(id)), 0) + 1;
      return {
        ...prev,
        [id]: { source: "", date: "", image: "" },
      } as ObituaryMap;
    });
  }, [memorial]);

  useEffect(() => {
    setChanged({});
    setCreated({});
  }, [isEditing]);

  return (
    <Box
      component="form"
      onSubmit={async (e) => {
        // Only used to get validation on fields with required.
        // We wont extract the values from the form.
        e.preventDefault();

        if (Object.values(created).some(({ source }) => source == null)) {
          enqueueSnackbar("Du måste ladda upp en dödsannons.", {
            variant: "error",
          });
          setIsEditing(true);
          return;
        }

        if (
          Object.values(created).some(
            ({ date }) => date == null || (date as unknown as string) === "",
          )
        ) {
          enqueueSnackbar("Du måste ange publiceringsdatum.", {
            variant: "error",
          });
          setIsEditing(true);
          return;
        }

        setChanged({});
        setCreated({});

        const allCreateJobs = Object.values(created).map(async (createData) => {
          const action = api.operations["case.case:add-obituary"];
          if (action == null)
            throw new Error('Invalid action "case.case:add-obituary".');

          const source = createData.source;
          const date = dayjs(createData.date).format("YYYY-MM-DD");

          if (source == null)
            throw new Error("Du måste ladda upp en dödsannons.");
          if (date == null) throw new Error("Du måste ange publiceringsdatum.");

          const response = await action.call({
            params: { memorial_id: memorial.id },
            body: { source, date },
          });

          if (!response.ok) {
            enqueueSnackbar(
              "Ett fel inträffade, kunde inte skapa dödsannons.",
              {
                variant: "error",
              },
            );
            throw new Error("Kunde inte uppdatera dödsannons");
          }

          const data = await response.json();
          return {
            obituary: data as TObituary,
            image: createData?.image ?? null,
          };
        });

        const allEditJobs = Object.entries(changed).map(
          async ([idRaw, changes]) => {
            const id = Number.parseInt(idRaw);

            const action = api.operations["case.case:update-obituary"];
            if (!action) {
              throw new Error('Invalid action "case.case:update-obituary".');
            }

            const obituary = memorial.obituaries.find((o) => o.id === id);
            const source = changes.source ?? obituary?.source;
            const date = dayjs(
              changes.date ?? new Date(obituary?.date ?? ""),
            ).format("YYYY-MM-DD");

            const response = await action.call({
              params: { obituary_id: id },
              body: { source, date },
            });

            if (!response.ok) {
              enqueueSnackbar(
                "Ett fel inträffade, kunde inte uppdatera dödsannons.",
                {
                  variant: "error",
                },
              );
              throw new Error("Kunde inte uppdatera dödsannons");
            }

            const data = await response.json();
            return {
              obituary: data as TObituary,
              image: changes?.image ?? null,
            };
          },
        );

        const imageUploadJobs = (
          await Promise.all([...allCreateJobs, ...allEditJobs])
        ).map(async ({ obituary, image }) => {
          if (image == null) return obituary;

          const action = api.operations["case.case:upload-obituary-image"];
          if (!action)
            throw new Error(
              'Invalid action "case.case:upload-obituary-image".',
            );

          const formData = new FormData();
          formData.append("file", image);

          const response = await action.call({
            params: { obituary_id: obituary.id },
            body: formData,
          });

          if (!response.ok) {
            enqueueSnackbar(
              "Ett fel inträffade, kunde inte ladda upp dödsannons. Se till att du har rätt bildformat.",
              { variant: "error" },
            );
            throw new Error("Kunde inte ladda upp bild.");
          }

          const data = await response.json();
          return { ...obituary, image: data.image } as TObituary;
        });

        const newObituaries = await Promise.all(imageUploadJobs);

        setMemorial((prev) => {
          const newPrev = structuredClone(prev);

          newPrev.obituaries = newPrev.obituaries
            .filter((o) => newObituaries.every((no) => no.id !== o.id))
            .concat(newObituaries)
            .sort((a, b) => a.id - b.id);

          return newPrev;
        });

        setIsEditing(false);
      }}
    >
      <MuiCard>
        <CardHeader
          title="Dödsannons"
          isEditable
          toggled={isEditing}
          onChange={(v) => {
            setIsEditing(v);
            setChanged([]);
          }}
        />
        <MuiCardContent>
          {memorial.obituaries
            .map((obituary) => [obituary, "edit" as "edit" | "create"] as const)
            .concat(
              isEditing
                ? Object.keys(created).map(
                    (id) =>
                      [
                        {
                          id: Number.parseInt(id),
                          source: "",
                          date: "",
                          image: "",
                        },
                        "create",
                      ] as const,
                  )
                : [],
            )
            .flatMap(([obituary, type]) => [
              <Obituary
                type={type}
                key={`ob-${type}-${obituary.id}`}
                isEditing={isEditing}
                obituary={obituary}
                onDelete={async () => {
                  if (type === "create") {
                    setCreated((prev) => {
                      const newPrev = structuredClone(prev);
                      delete newPrev[obituary.id];
                      return newPrev;
                    });
                  } else {
                    const action =
                      api.operations["case.memorial:delete-obituary"];
                    if (action == null) {
                      throw new Error("Invalid action", {
                        cause: "case.memorial:delete-obituary",
                      });
                    }

                    const response = await action.call({
                      params: { obituary_id: obituary.id },
                    });

                    if (!response.ok) {
                      enqueueSnackbar("Kunde inte ta bort dödsannons", {
                        variant: "error",
                      });
                      return;
                    }

                    setChanged((prev) => {
                      const newPrev = structuredClone(prev);
                      delete newPrev[obituary.id];
                      return newPrev;
                    });

                    setMemorial((prev) => {
                      const newPrev = structuredClone(prev);
                      newPrev.obituaries = newPrev.obituaries.filter(
                        (o) => o.id !== obituary.id,
                      );
                      return newPrev;
                    });

                    enqueueSnackbar("Dödsannons borttagen", {
                      variant: "success",
                    });
                  }
                }}
                onChange={(data) => {
                  if (type == "create") {
                    const prevData = created[obituary.id] ?? {};
                    setCreated((prev) => ({
                      ...prev,
                      [obituary.id]: { ...prevData, ...data },
                    }));
                  } else {
                    const prevData = changed[obituary.id] ?? {};
                    setChanged((prev) => ({
                      ...prev,
                      [obituary.id]: { ...prevData, ...data },
                    }));
                  }
                }}
              />,
              <Divider sx={{ my: 3 }} key={`div-${obituary.id}`} />,
            ])
            .slice(0, -1)}
        </MuiCardContent>

        {isEditing && (
          <MuiCardActions
            sx={{
              padding: [1, 2, 3, 2],
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              onClick={addObituary}
              variant="text"
              startIcon={<AddIcon />}
              size="medium"
            >
              Lägg till dödsannons
            </Button>

            <Button variant="contained" size="medium" type="submit">
              Spara
            </Button>
          </MuiCardActions>
        )}
      </MuiCard>
    </Box>
  );
};

export default Obituaries;
