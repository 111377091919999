import { memo, useCallback, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import * as Bananas from "bananas-commerce-admin";

import PrintIcon from "@mui/icons-material/Print";

import SubtitledLabel from "@/components/SubtitledLabel";
import { Customer } from "@/extensions/klarahill/types";
import { Ad, ProofPayload } from "@/extensions/obituary/types";
import AdList from "@/extensions/obituary/components/AdList";
import getAdsFromFormData from "@/extensions/obituary/components/AdList/getAdsFromFormData";
import { Nullish } from "@/utils/types";

type SendTo = "mainContact" | "other" | "print";

function assertIsSendTo(value: string): asserts value is SendTo {
  if (value !== "mainContact" && value !== "other" && value !== "print") {
    throw new Error("Invalid value");
  }
}

export interface ProofDialogProps extends Omit<DialogProps, "onClose"> {
  ads: Ad[];
  mainContact: Nullish<Customer>;
  onSubmit?: () => void;
  onClose: () => void;
}

const ProofDialog: React.FC<ProofDialogProps> = ({
  ads,
  mainContact,
  onSubmit,
  onClose,
  open,
  ...props
}) => {
  const api = Bananas.useApi();
  const { enqueueSnackbar } = useSnackbar();

  const [sendTo, setSendTo] = useState<SendTo>("mainContact");

  const handleRadioChange = useCallback(
    (_: React.ChangeEvent<HTMLInputElement>, value: string) => {
      assertIsSendTo(value);
      setSendTo(value);
    },
    [],
  );

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const formData = new FormData(event.currentTarget);
      const sendTo = formData.get("sendTo") as SendTo;
      const obituary_ad_ids = getAdsFromFormData(formData);

      if (obituary_ad_ids.length === 0) {
        enqueueSnackbar("Inga annonser valda.", { variant: "error" });
        return;
      }

      const recipients: string[] = [];
      if (sendTo === "mainContact") {
        if (mainContact != null) recipients.push(mainContact.email);
      } else if (sendTo === "other") {
        const email = formData.get("otherEmail");
        if (typeof email === "string") recipients.push(email);
      }

      if (recipients.length === 0) {
        enqueueSnackbar("Inga mottagare att skicka till.", {
          variant: "error",
        });
        return;
      }

      const payload: ProofPayload = { recipients, obituary_ad_ids };
      const action = api.operations["obituary.ad:send-review-email"];
      if (action == null)
        throw new Error('Invalid action "obituary.ad:send-review-email".');
      const response = await action.call({ body: payload });

      if (response.ok) {
        enqueueSnackbar(`Korrektur skickad till ${recipients.join(", ")}.`, {
          variant: "success",
        });
        onSubmit?.();
        onClose();
      } else {
        enqueueSnackbar("Kunde inte skicka korrektur.", { variant: "error" });
      }
    },
    [onSubmit],
  );

  return (
    <Dialog
      fullWidth
      scroll="paper"
      open={open}
      onClose={onClose}
      {...props}
      component="form"
      // @ts-expect-error - Wrong type reported, rendered type is a form
      onSubmit={handleSubmit}
    >
      <DialogTitle>Skicka korrektur</DialogTitle>

      <DialogContent>
        <Stack gap={2}>
          <FormControl>
            <RadioGroup
              name="sendTo"
              value={sendTo}
              onChange={handleRadioChange}
            >
              <FormControlLabel
                value="mainContact"
                control={<Radio />}
                label={
                  <SubtitledLabel
                    title="Skicka till anhörig"
                    subtitle={
                      mainContact != null
                        ? `${mainContact.first_name} ${mainContact.last_name} <${mainContact.email}>`
                        : null
                    }
                  />
                }
              />

              <FormControlLabel
                value="other"
                control={<Radio />}
                label={
                  <Typography variant="subtitle2">
                    Skicka till annan mottagare
                  </Typography>
                }
              />

              <FormControlLabel
                value="print"
                control={<Radio />}
                label={
                  <Typography variant="subtitle2">
                    Skriv ut och hantera manuellt
                  </Typography>
                }
              />
            </RadioGroup>
          </FormControl>

          {sendTo === "other" && (
            <TextField
              fullWidth
              required
              name="otherEmail"
              type="email"
              label="E-postadress"
            />
          )}

          <AdList allSelected ads={ads} label="Annonser" gap={2} />

          {sendTo === "print" && (
            <Button
              variant="contained"
              startIcon={<PrintIcon />}
              onClick={() => alert("Not printplemented.")}
            >
              {"Skriv\u00A0ut"}
            </Button>
          )}
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Avbryt</Button>
        <Button disabled={sendTo === "print"} type="submit">
          Skicka
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(ProofDialog);
