import React, { memo, useCallback, useState } from "react";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList } from "@mui/lab";

export interface Tab {
  value: string;
  label: string;
}

interface TabbedSidebarProps {
  children: React.ReactNode;
  tabs: Tab[];
  value?: string;
  width?: number;
}

/**
 * A tabbed sidebar component.
 * The component takes a list of tabs with matching `TabPanel` children.
 * Make sure that the `value` prop of each tab matches the `value` prop of the corresponding `TabPanel`.
 *
 * @example
 * ```tsx
 * <TabbedSidebar
 *   tabs={[
 *     { value: "tab1", label: "Tab 1" },
 *     { value: "tab2", label: "Tab 2" },
 *   ]}
 * >
 *   <TabPanel value="tab1">Tab 1 content</TabPanel>
 *   <TabPanel value="tab2">Tab 2 content</TabPanel>
 * </TabbedSidebar>
 * ```
 */
const TabbedSidebar: React.FC<TabbedSidebarProps> = ({
  children,
  tabs,
  value: initialValue,
  width,
}) => {
  width ??= 420;

  const [value, setValue] = useState(initialValue ?? tabs?.[0]?.value ?? "0");

  const handleChange = useCallback(
    (_: React.SyntheticEvent, newValue: string) => {
      setValue(newValue);
    },
    [],
  );

  return (
    <Box
      component="aside"
      aria-label="Sidopanel"
      sx={{
        width: "100%",
        maxWidth: width,
        height: "100%",
        overflow: "scroll",
        bgcolor: "background.paper",
        borderRight: (theme) => `1px solid ${theme.palette.divider}`,
      }}
    >
      <TabContext value={value}>
        <TabList
          aria-label="Flikar"
          orientation="horizontal"
          onChange={handleChange}
          sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
        >
          {tabs.map(({ value, label }, i) => {
            const key = value ?? i.toString();
            return <Tab key={key} value={key} label={label} sx={{ flex: 1 }} />;
          })}
        </TabList>

        {children}
      </TabContext>
    </Box>
  );
};

export default memo(TabbedSidebar);
