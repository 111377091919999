import { Context, createContext, useContext } from "react";

import { Case } from "@/extensions/klarahill/types";
import { Layout } from "@/extensions/obituary/types";
import * as object from "@/utils/object";
import { Nullish } from "@/utils/types";

import { Container } from "./Item/Container";
import { Action } from "../reducer";
import { Item, Story } from "./shared";

export interface StoryContextProps {
  c: Case;
  change: <T extends Item>(path: object.Path, payload: Partial<T>) => void;
  dispatch: React.Dispatch<Action<Item>>;
  story: Story;
  getContainerVariants: (path: object.Path) => Container["variant"][];
  openContainer: Nullish<string>;
  setOpenContainer: React.Dispatch<React.SetStateAction<Nullish<string>>>;
  layout: Layout;
}

export const StoryContext: Context<StoryContextProps> =
  createContext<StoryContextProps>({} as StoryContextProps);

export const useStory = (): StoryContextProps => useContext(StoryContext);
