import { useCallback } from "react";
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

import { MemorialAttendee } from "@/extensions/klarahill/types";

import * as format from "@/utils/format";
import { Nullish } from "@/utils/types";

interface IconButtonRowProps {
  buttons: Nullish<{ icon: React.ReactNode; onClick: () => void }>[];
}

const IconButtonRow: React.FC<IconButtonRowProps> = ({ buttons }) => (
  <Box
    sx={{
      px: 0.25,
      border: (theme) => `solid 1px ${theme.palette.grey[600]}`,
      borderRadius: 1,
      display: "flex",

      "& > :last-child": {
        border: "none",
      },

      "& *": { fill: (theme) => `solid 1px ${theme.palette.grey[600]}` },
    }}
  >
    {buttons
      .filter((button): button is NonNullable<typeof button> => button != null)
      .map(({ icon, onClick }, i) => (
        <Box
          key={i}
          px={0.75}
          py={0.25}
          onClick={onClick}
          sx={{
            boxSizing: "content-box",
            height: "18px",
            borderRight: (theme) => `solid 1px ${theme.palette.grey[600]}`,
            "& svg": {
              width: "0.75em",
              height: "0.75em",
            },
          }}
        >
          {icon}
        </Box>
      ))}
  </Box>
);

interface AttendeesProps {
  icon?: React.ReactNode;
  header: string;
  attendees: MemorialAttendee[];
  type: "guest" | "reserve";
  onDelete?: (id: number) => void;
  onMakeGuest?: (id: number) => void;
  onEdit?: (id: number) => void;
}

const Attendees: React.FC<AttendeesProps> = ({
  attendees,
  header,
  icon,
  onDelete,
  onEdit,
  onMakeGuest,
  type,
}) => {
  const handleClick = useCallback((id: number) => onEdit?.(id), [onEdit]);

  return (
    <Box>
      <Grid item xs={12} sx={{ width: "100%" }}>
        <TableContainer component={Paper}>
          <Box
            p={2}
            pb={0}
            sx={{
              color: (theme) => theme.palette.text.primary,
              wordSpacing: "6px",
              display: "flex",
              gap: 1,
              alignItems: "center",
              "& svg": {
                height: 20,
                width: 20,
              },
            }}
          >
            {icon}
            <Typography variant="body1" sx={{ fontWeight: 500 }}>
              {header}
            </Typography>
          </Box>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: "20%" }}>Namn</TableCell>
                <TableCell sx={{ width: "30%" }}>Telefon</TableCell>
                <TableCell sx={{ width: "50%" }}>Meddelande</TableCell>
                <TableCell sx={{ width: "0" }} align="right">
                  Antal
                </TableCell>
                <TableCell sx={{ width: "0" }} align="right"></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {attendees.map((attendee) => {
                return (
                  <TableRow key={attendee.id} sx={{ cursor: "pointer" }} hover>
                    <TableCell onClick={() => handleClick(attendee.id)}>
                      {attendee.name}
                    </TableCell>
                    <TableCell onClick={() => handleClick(attendee.id)}>
                      {format.swedishPhoneNumber(attendee.phone) ??
                        attendee.phone}
                    </TableCell>
                    <TableCell onClick={() => handleClick(attendee.id)}>
                      {attendee.info}
                    </TableCell>
                    <TableCell
                      onClick={() => handleClick(attendee.id)}
                      align="right"
                    >
                      {attendee.count}
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <IconButtonRow
                          buttons={[
                            type == "reserve"
                              ? {
                                  icon: (
                                    <FileDownloadOutlinedIcon
                                      sx={{ transform: "rotate(180deg)" }}
                                    />
                                  ),
                                  onClick: () => onMakeGuest?.(attendee.id),
                                }
                              : null,
                            {
                              icon: <DeleteOutlineOutlinedIcon />,
                              onClick: () => onDelete?.(attendee.id),
                            },
                          ]}
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}

              {Boolean(type === "guest" && attendees.length > 0) && (
                <TableRow sx={{ cursor: "pointer" }}>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell align="right">
                    <Typography variant="body2" sx={{ width: "max-content" }}>
                      Gäster totalt&nbsp;&nbsp;&nbsp;&nbsp;
                      <strong>
                        {attendees.reduce((a, b) => a + b.count, 0)}
                      </strong>
                    </Typography>
                  </TableCell>
                  <TableCell />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Box>
  );
};

export default Attendees;
