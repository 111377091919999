import { memo, useCallback } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  SxProps,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import * as object from "@/utils/object";

import { useStory } from "../context";

const styles: Record<string, SxProps> = {
  root: {
    borderBottom: "1px solid",
    borderColor: "divider",
    "&::before": { display: "none" },
  },

  summary: {
    transition: "background-color 150ms",
    "&:hover": { bgcolor: "#fcfcfc" },
  },

  title: {
    textTransform: "uppercase",
    fontWeight: 500,
    letterSpacing: 0.2,
  },

  details: {
    padding: "16px 0 !important",
    bgcolor: "grey.50",
    borderTop: "1px solid ",
    borderColor: "divider",
    "& > *": { display: "flex", flexDirection: "column", pb: 1 },

    // Use lobotomized owl selector instead of `gap` to ensure a smooth hover effect.
    "& > * > * + *": { paddingTop: 3 },
  },
};

export interface EditableContainerProps {
  children: React.ReactNode;
  path: object.Path;
  title: string;
  variant: string;
}

const EditableContainer: React.FC<EditableContainerProps> = ({
  children,
  title,
  variant,
}) => {
  const { openContainer, setOpenContainer } = useStory();

  const handleToggle = useCallback(() => {
    setOpenContainer((prev) => (prev === variant ? null : variant));
  }, [variant, setOpenContainer]);

  return (
    <Accordion
      square
      disableGutters
      elevation={0}
      sx={styles.root}
      onChange={handleToggle}
      expanded={openContainer === variant}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={styles.summary}>
        <Typography variant="subtitle2" sx={styles.title}>
          {title}
        </Typography>
      </AccordionSummary>

      <AccordionDetails sx={styles.details}>{children}</AccordionDetails>
    </Accordion>
  );
};

export default memo(EditableContainer);
