import { useCallback, useMemo, useReducer } from "react";

/*
 * This hook is used to centrally manage releated state of a single component.
 * Uses a simple reducer to update the settings, so that you don't have to spread old properties.
 **/
function useSettings<T>(defaults: T) {
  const defaultSettings = useMemo<T>(() => defaults, [defaults]);

  const [settings, setSettings] = useReducer(
    (s: T, ns: Partial<T>) => ({ ...s, ...ns }),
    defaultSettings,
  );

  const resetSettings = useCallback(
    () => setSettings(defaultSettings),
    [defaultSettings],
  );

  return { settings, setSettings, resetSettings, defaultSettings } as const;
}

export default useSettings;
