import {
  createContext,
  memo,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

import { Case } from "@/extensions/klarahill/types";
import CreateAdDialog from "@/extensions/obituary/components/CreateAdDialog";
import ProofDialog from "@/extensions/obituary/components/ProofDialog";
import PublishDialog from "@/extensions/obituary/components/PublishDialog";
import { Ad } from "@/extensions/obituary/types";
import { Nullish } from "@/utils/types";

export type Dialogs = "create" | "proof" | "publish";

export interface ObituaryDialogContext {
  openDialog: (dialog: Dialogs) => () => void;
  closeDialog: () => void;
}

export const ObituaryDialogContext = createContext<ObituaryDialogContext>(
  {} as ObituaryDialogContext,
);

export const useObituaryDialog = () => useContext(ObituaryDialogContext);

export interface ObituaryDialogContextProviderProps {
  ads: Ad[];
  c: Case;
  children: ReactNode;
  onAdCreate?: (ad: Ad) => void;
  onDialogSuccess?: () => void;
}

export const ObituaryDialogContextProvider: React.FC<
  ObituaryDialogContextProviderProps
> = ({ ads, c, children, onAdCreate, onDialogSuccess }) => {
  const [openDialog, setOpenDialog] = useState<Nullish<Dialogs>>();

  const handleOpenDialog = useCallback(
    (dialog: Dialogs) => () => setOpenDialog(dialog),
    [],
  );

  const handleCloseDialog = useCallback(() => setOpenDialog(null), []);

  const mainContact = useMemo(
    () => c?.customers.find((c) => c.is_main_contact),
    [c?.customers],
  );

  const value = useMemo(
    () => ({
      openDialog: handleOpenDialog,
      closeDialog: handleCloseDialog,
    }),
    [handleOpenDialog, handleCloseDialog],
  );

  return (
    <ObituaryDialogContext.Provider value={value}>
      {children}

      {onAdCreate != null && (
        <CreateAdDialog
          ads={ads}
          c={c}
          open={openDialog === "create"}
          onClose={handleCloseDialog}
          onSubmit={onAdCreate}
        />
      )}

      <ProofDialog
        ads={ads}
        open={openDialog === "proof"}
        onClose={handleCloseDialog}
        mainContact={mainContact}
        onSubmit={onDialogSuccess}
      />

      <PublishDialog
        ads={ads}
        open={openDialog === "publish"}
        onClose={handleCloseDialog}
        onSubmit={onDialogSuccess}
      />
    </ObituaryDialogContext.Provider>
  );
};

export default memo(ObituaryDialogContextProvider);
