import { Box } from "@mui/material";

import { Card } from "@/extensions/klarahill/components/Card";
import ErrorCard from "@/extensions/klarahill/components/ErrorCard";
import * as styles from "@/extensions/klarahill/pages/case/shared";
import Message from "./Message";

export const Messages: React.FC<styles.CaseTabProps> = ({
  case: {
    memorial: { messages },
  },
}) => {
  const removedMessages = messages.filter(
    (message) => message?.status === "removed",
  );
  const hasMessages = Array.isArray(messages) && messages.length > 0;

  return (
    <Box sx={styles.content}>
      <Box sx={styles.cards}>
        <Box sx={styles.primaryCards}>
          {hasMessages ? (
            <Box>
              {messages.map((message) => (
                <Message key={message.id} initialMessage={message} />
              ))}
            </Box>
          ) : (
            <ErrorCard errorMessage="Det finns inga minnen för det här minnesrummet." />
          )}
        </Box>

        <Box sx={styles.secondaryCards}>
          <Card
            isCompact
            title="Minnen"
            layout={[
              [
                {
                  name: "Publicerade",
                  type: "text",
                  value: String(messages.length - removedMessages.length),
                },
              ],
              [
                {
                  name: "Dolda",
                  type: "text",
                  value: String(removedMessages.length),
                },
              ],
            ]}
          />
        </Box>
      </Box>
    </Box>
  );
};
