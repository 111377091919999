import { useState, useMemo } from "react";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { SxProps } from "@mui/system";
import { useSearchParams } from "react-router-dom";
import * as Bananas from "bananas-commerce-admin";

import ArticleIcon from "@mui/icons-material/ArticleOutlined";
import CardGiftcardOutlinedIcon from "@mui/icons-material/CardGiftcardOutlined";
import DiversityIcon from "@mui/icons-material/Diversity1Outlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import FireplaceIcon from "@mui/icons-material/FireplaceOutlined";
import FlowerIcon from "@mui/icons-material/LocalFloristOutlined";

import { Case } from "@/extensions/klarahill/types";
import * as featureFlag from "@/utils/featureFlag";
import * as params from "@/utils/searchParams";
import { Nullish } from "@/utils/types";

import { CaseDetails } from "./Case";
import { Gifts } from "./Gifts";
import { Memorial } from "./Memorial";
import { Messages } from "./Messages";
import { Orders } from "./Orders";
import { Reception } from "./Reception";

type Tab =
  | "case"
  | "memorial"
  | "reception"
  | "messages"
  | "orders"
  | "gifts"
  | "obituary";

const tabListSx: SxProps = {
  height: "52px !important",
  maxHeight: "52px !important",
};

const tabSx: SxProps = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  minHeight: "52px !important",
  maxHeight: "52px !important",
  gap: 1,
  "& svg": {
    transform: "translateY(2px)",
    color: "currentColor",
  },
};

const CasesDetailsPage: Bananas.types.PageComponent<Case> = ({ data }) => {
  const api = Bananas.useApi();

  const [searchParams] = useSearchParams();
  let activeTab: Nullish<Tab> = searchParams.get("tab") as Tab;

  if (activeTab == null) {
    activeTab = "case"; // Default to the `Case` view
    params.setManually("tab", activeTab);
  }

  const [tab, setTab] = useState(activeTab);

  const { first_name, last_name } = data.deceased;
  const fullName = `${first_name} ${last_name}`;

  const hasMemorial = data.memorial != null;
  const showObituary = featureFlag.active.has("OBITUARY");

  const contrib = useMemo(() => api.findContrib("case"), [api]);

  return (
    <TabContext value={tab}>
      <Bananas.TitleBar title={fullName} back />

      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <TabList
          scrollButtons
          selectionFollowsFocus
          variant="scrollable"
          orientation="horizontal"
          aria-label="Navigation för begravningsärende."
          sx={tabListSx}
          onChange={(_, tab: Tab) => {
            setTab(tab);
            params.setManually("tab", tab);
          }}
        >
          <Tab sx={tabSx} label="Ärende" value="case" icon={<ArticleIcon />} />

          <Tab
            sx={tabSx}
            disabled={!hasMemorial}
            label="Minnesrum"
            value="memorial"
            icon={<FireplaceIcon />}
          />

          <Tab
            sx={tabSx}
            disabled={!hasMemorial}
            label="Minnen"
            value="messages"
            icon={<DiversityIcon />}
          />

          <Tab
            sx={tabSx}
            disabled={!hasMemorial}
            label="Minnesstund"
            value="reception"
            icon={<CalendarMonthOutlinedIcon />}
          />

          <Tab
            sx={tabSx}
            disabled={!hasMemorial}
            label="Blomsterordrar"
            value="orders"
            icon={<FlowerIcon />}
          />

          <Tab
            sx={tabSx}
            disabled={!hasMemorial}
            label="Minnesgåvor"
            value="gifts"
            icon={<CardGiftcardOutlinedIcon />}
          />

          {showObituary &&
            contrib.map(({ id, component }) => (
              <Tab
                sx={tabSx}
                key={id}
                label={component?.title ?? "MISSING TITLE"}
                value={id}
                // @ts-expect-error - TODO
                icon={component?.icon}
              />
            ))}
        </TabList>
      </Box>

      {tab === "case" ? (
        <CaseDetails case={data} />
      ) : tab === "memorial" ? (
        <Memorial case={data} />
      ) : tab === "reception" ? (
        <Reception case={data} />
      ) : tab === "messages" ? (
        <Messages case={data} />
      ) : tab === "orders" ? (
        <Orders case={data} />
      ) : tab === "gifts" ? (
        <Gifts case={data} />
      ) : (
        contrib.map((operation, i) => (
          <TabPanel key={i} value={tab} sx={{ p: 0, height: "100%" }}>
            <Bananas.ComponentLoader
              operation={operation}
              query={{ case_id: data.id }}
            />
          </TabPanel>
        ))
      )}
    </TabContext>
  );
};

export default CasesDetailsPage;
