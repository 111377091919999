import React from "react";
import ReactDOM from "react-dom/client";
import * as Bananas from "bananas-commerce-admin";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { createTheme } from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import "dayjs/locale/sv";

// @ts-ignore
import Logo from "../assets/svg/klarahill-logo.svg";

import * as Klarahill from "@/extensions/klarahill";
import * as Obituary from "@/extensions/obituary";
import { Redirect } from "@/extensions/klarahill/components/Redirect";

const fontFamily = [
  "acumin-variable",
  "-apple-system",
  "BlinkMacSystemFont",
  '"Segoe UI"',
  '"Roboto"',
  '"Helvetica Neue"',
  "Arial",
  "sans-serif",
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join(",");

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

const theme = createTheme({
  typography: {
    fontFamily,
    fontWeightLight: 400,
    fontWeightRegular: 450,
    fontWeightMedium: 550,
    fontWeightBold: 650,
  },
  palette: { primary: { main: "#003444" }, secondary: { main: "#E5E5E5" } },
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="sv">
      <QueryClientProvider client={queryClient}>
        <Bananas.App
          api={import.meta.env.API_SCHEMA_URL}
          basename={import.meta.env.BASE_URL}
          dashboard={Redirect}
          logo={Logo}
          theme={theme}
          extensions={[
            Bananas.pos.router,
            Bananas.bananas.router,
            Klarahill.orderRouter,
            Klarahill.caseRouter,
          ]}
          contrib={{ ...Obituary.contrib.obituaries }}
          navigation={{ ...Klarahill.navigation }}
        />
      </QueryClientProvider>
    </LocalizationProvider>
  </React.StrictMode>,
);
