import {
  Box,
  CardHeader as MuiCardHeader,
  Switch,
  Typography,
} from "@mui/material";

export interface CardHeaderProps {
  title: string;
  isEditable?: boolean;
  isDisabled?: boolean;
  onChange?: (v: boolean) => void;
  toggled?: boolean;
}

const CardHeader: React.FC<CardHeaderProps> = ({
  title,
  isEditable = false,
  isDisabled = false,
  onChange,
  toggled,
}) => (
  <MuiCardHeader
    sx={{ opacity: isDisabled && toggled ? 0.5 : 1 }}
    title={
      <Typography sx={{ fontWeight: 500 }} variant="body1">
        {title}
      </Typography>
    }
    action={
      isEditable && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography sx={{ color: "grey.600" }} variant="body2">
            Ändra
          </Typography>

          <Switch
            disabled={isDisabled && toggled}
            checked={Boolean(toggled)}
            onChange={(_, v) => onChange?.(v)}
          />
        </Box>
      )
    }
  />
);

export default CardHeader;
