import NewspaperIcon from "@mui/icons-material/Newspaper";
import * as Bananas from "bananas-commerce-admin";

import ObituaryList from "@/extensions/obituary/pages/list";

export const contrib: Record<string, Bananas.types.ContribComponentMap> = {
  obituaries: {
    "case.case:retrieve": {
      title: "Annonser",
      // @ts-expect-error - TODO
      icon: <NewspaperIcon />,
      component: () => ObituaryList,
    },
  },
};
