import { Box, Button, TextField, Typography } from "@mui/material";

import FormDatePicker from "@/components/FormDatePicker";
import ReadField from "@/extensions/klarahill/components/Card/ReadField";
import type { Obituary } from "@/extensions/klarahill/types";
import { ObituaryChangeData } from "./shared";

interface ObituaryCardProps {
  type: "edit" | "create";
  obituary: Obituary;
  isEditing: boolean;
  onChange?: (data: ObituaryChangeData) => void;
  onDelete?: () => void;
}

const Obituary: React.FC<ObituaryCardProps> = ({
  type,
  obituary,
  isEditing,
  onChange,
  onDelete,
  ...props
}) => {
  if (!isEditing) {
    return (
      <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
        <Box sx={{ display: "flex", gap: 3 }}>
          <Box sx={{ flex: 1 }}>
            <ReadField
              type="text"
              isCompact={false}
              name="Publicerad i"
              value={obituary.source}
            />
          </Box>

          <Box sx={{ flex: 1 }}>
            <ReadField
              type="date"
              isCompact={false}
              name="Publiceringsdatum"
              value={obituary.date}
            />
          </Box>
        </Box>

        <ReadField
          type="obituary"
          isCompact={false}
          name="Dödsannons"
          value={obituary.image}
        />
      </Box>
    );
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
      <Box sx={{ display: "flex", gap: 3 }}>
        <Box sx={{ flex: 1 }}>
          <TextField
            name="source"
            fullWidth
            defaultValue={obituary.source}
            label="Publicerad i"
            variant="outlined"
            onChange={(e) => onChange?.({ source: e.target.value })}
            required={true}
            inputProps={{
              maxLength: "maxLength" in props ? props.maxLength : undefined,
            }}
          />
        </Box>

        <Box sx={{ flex: 1 }}>
          <FormDatePicker
            label="Publiceringsdatum"
            name="date"
            value={obituary.date}
            onChange={(value) => {
              if (value == null) return;
              const date = new Date(value);
              if (!Number.isNaN(date.getTime())) onChange?.({ date });
            }}
          />
        </Box>
      </Box>

      <Box sx={{ display: "flex", gap: 3 }}>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            gap: 0.5,
            flexDirection: "column",
            justifyContent: "flex-start",
          }}
        >
          <Typography variant="subtitle2" sx={{ color: "grey.600" }}>
            Dödsannons
          </Typography>

          <input
            name="image"
            type="file"
            accept="image/*"
            required={type === "create"}
            onChange={(e) => onChange?.({ image: e.target.files![0] })}
          />
        </Box>

        <Box
          sx={{
            flex: 1,
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Button variant="outlined" size="medium" onClick={onDelete}>
            Ta bort
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Obituary;
