import { Ad } from "@/extensions/obituary/types";

function getAdsFromFormData(formData: FormData): Ad["id"][] {
  const data = Object.fromEntries(formData.entries());

  const adIds: Ad["id"][] = [];
  for (const [key] of Object.entries(data)) {
    if (key.startsWith("ad-")) adIds.push(Number.parseInt(key.slice(3)));
  }

  return adIds;
}

export default getAdsFromFormData;
