import { memo } from "react";
import { Stack, StackProps } from "@mui/material";
import dayjs from "dayjs";

import LabeledValue from "@/components/LabeledValue";
import { Ad } from "@/extensions/obituary/types";
import * as format from "@/utils/format";

export interface AdMetaProps extends StackProps {
  ad: Ad;
  showPublishInfo?: boolean;
}

const AdMeta: React.FC<AdMetaProps> = ({
  ad,
  showPublishInfo,
  sx,
  ...props
}) => (
  <Stack gap={2} sx={sx} {...props}>
    <Stack gap={1}>
      <LabeledValue
        compact
        label="Bredd"
        value={format.metric(ad.width, "mm")}
      />

      <LabeledValue
        compact
        label="Höjd"
        value={format.metric(ad.height, "mm")}
      />

      <LabeledValue
        compact
        label="Pris inkl. moms"
        value={format.price(ad.price)}
      />
    </Stack>

    {showPublishInfo && (
      <Stack gap={1}>
        <LabeledValue
          compact
          label="Införandedatum"
          value={
            ad.publication_date == null
              ? "—"
              : dayjs(ad.publication_date).format("YYYY-MM-DD")
          }
        />

        <LabeledValue
          compact
          label="Skickas in senast"
          value={
            ad.editing_deadline == null
              ? "—"
              : dayjs(ad.editing_deadline).format("YYYY-MM-DD HH:mm")
          }
        />

        <LabeledValue
          compact
          label="Godkänd av kund"
          value={ad.approved_at != null ? "Ja" : "Nej"}
        />
      </Stack>
    )}
  </Stack>
);

export default memo(AdMeta);
