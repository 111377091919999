import React, { useCallback, useEffect, useState, useRef } from "react";
import { Box, BoxProps, SxProps } from "@mui/material";

import ListTabs, { ListTabsProps } from "@/components/ListTabs";
import Zoom from "@/components/Zoom";
import useSessionStorage from "@/hooks/useSessionStorage";

import { Ad, Layout } from "@/extensions/obituary/types";

/**
 * Easing function for quadratic easing in and out.
 * Makes the transition a bit smoother.
 */
function easeInOutQuad(x: number): number {
  return x < 0.5 ? 2 * x * x : 1 - Math.pow(-2 * x + 2, 2) / 2;
}

const rootSx: SxProps = {
  position: "relative",
  width: "100%",
  height: "100%",
  overflow: "hidden",
  bgcolor: "grey.100",
};

const tabsSx: SxProps = {
  position: "absolute",
  top: 8,
  left: 8,
  zIndex: 8000,
};

const zoomSx: SxProps = {
  position: "absolute",
  bottom: 16,
  right: 16,
};

const imgContainerSx = (
  opacity: number,
  size: number,
  layout: Layout,
): SxProps => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: `translate(-50%, -50%) scale(${size})`,
  width: layout === "OBITUARY_ONE_COLUMN" ? "38mm" : "70mm",
  maxHeight: "100%",
  opacity,
});

const imgSx: SxProps = {
  height: "100%",
  width: "100%",
  bgcolor: "white",
};

interface PreviewProps
  extends Omit<BoxProps, "onChange">,
    Pick<ListTabsProps, "tabs"> {
  adId: Ad["id"];
  defaultSize?: number;
  duration?: number;
  layout?: Layout;
  src: string;
  sx?: SxProps;
  onTabChange: ListTabsProps["onChange"];
}

/**
 * Image preview that fades between two images.
 *
 * @param duration - Duration of the fade transition in milliseconds, default 200.
 */
const Preview: React.FC<PreviewProps> = ({
  adId,
  defaultSize = 1,
  duration = 150,
  layout = "OBITUARY_ONE_COLUMN",
  src,
  sx,
  tabs,
  onTabChange,
  ...props
}) => {
  const [oldSrc, setOldSrc] = useState(src);
  const [opacity, setOpacity] = useState(1);

  const [size, setSize] = useSessionStorage(
    "obituary-preview-size",
    defaultSize,
  );

  const interpolationRef = useRef<number>();

  useEffect(() => {
    if (typeof src !== "string") return;

    if (src !== oldSrc) {
      setOpacity(0);
      startInterpolation();
    }
  }, [src]);

  const startInterpolation = useCallback(() => {
    if (interpolationRef.current) {
      cancelAnimationFrame(interpolationRef.current);
    }

    const startTime = performance.now();

    const interpolate = (currentTime: number) => {
      const delta = currentTime - startTime;
      const progress = Math.min(delta / duration, 1);
      const ease = easeInOutQuad(progress);
      setOpacity(ease);

      if (ease < 1) {
        interpolationRef.current = requestAnimationFrame(interpolate);
      } else {
        setOldSrc(src);
      }
    };

    interpolationRef.current = requestAnimationFrame(interpolate);
  }, [duration, src]);

  const handleSizeChange = useCallback(
    (newSize: number) => setSize(newSize),
    [setSize],
  );

  return size == null ? null : (
    <Box sx={{ ...rootSx, ...sx }} {...props}>
      <ListTabs
        activeTab={adId.toString()}
        tabs={tabs}
        onChange={onTabChange}
        sx={tabsSx}
      />

      {oldSrc != null && oldSrc !== "" && (
        <Box sx={imgContainerSx(1 - opacity, size, layout)}>
          <Box sx={imgSx} component="img" src={oldSrc} />
        </Box>
      )}

      <Box sx={imgContainerSx(opacity, size, layout)}>
        <Box sx={imgSx} component="img" src={src} />
      </Box>

      <Zoom
        min={0.5}
        max={3}
        sx={zoomSx}
        zoom={size}
        onChange={handleSizeChange}
      />
    </Box>
  );
};

export default Preview;
