import { FunctionComponent, ReactNode } from "react";

import * as object from "@/utils/object";
import { UUID } from "@/utils/types";

import { Container } from "./Item/Container";
import { Date } from "./Item/Date";
import { Divider } from "./Item/Divider";
import { Image } from "./Item/Image";
import { Multiline } from "./Item/Multiline";
import { Singleline } from "./Item/Singleline";

export interface Item {
  type: string;
  variant?: string;
  /** `id` is not required by the backend, but is nessecary to disambiguate React keys. */
  id: UUID;
  /** Excludes the `Item` from rendering if `true`. */
  hidden?: boolean;
}

export type Story = Array<
  Item | Container | Image | Date | Divider | Multiline | Singleline
>;

export type Editable<T extends Item> = FunctionComponent<{
  children?: ReactNode;
  item: T;
  path: object.Path;
  noHeader?: boolean;
}>;

export const DEBOUNCE = 500;
export const MAX_IMAGE_HEIGHT = 30;
