/**
 * Use feature flags to roll out features/settings to dev/live on the different target environments at different times.
 * To add a new flag add the key to the type FeatureFlag below, then add it to the correct environment array.
 *
 * @example
 * import * as featureFlag from "@/utils/featureFlag";
 *
 * if (featureFlag.active.has("OBITUARY")) return "💀"
 */

export type FeatureFlag =
  /** Activates Obituary/Dödsannons editor. */
  "OBITUARY";

export const live: FeatureFlag[] = [];
export const dev: FeatureFlag[] = [...live, "OBITUARY"];

const features = import.meta.env.NODE_ENV === "production" ? live : dev;

export const active = new Set<FeatureFlag>(features);
