import { useState, useEffect } from "react";

import * as hash from "@/utils/hash";

function useHash<T>(obj: T) {
  const [state, setState] = useState<string>("");

  useEffect(() => {
    hash.object(obj).then(setState);
  }, [obj]);

  return state;
}

export default useHash;
