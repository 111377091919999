import { Emoticon } from "@/extensions/obituary/types";

const emoticons: Emoticon[] = [
  {
    code: ":heart:",
    name: "Hjärta",
    char: "\u2665",
  },
  {
    code: ":heart-outline:",
    name: "Konturerat Hjärta",
    char: "\u2661",
  },
  {
    code: ":red-heart:",
    name: "Rött Hjärta",
    char: "\u2665",
  },
  {
    code: ":red-heart-outline:",
    name: "Vitt Hjärta",
    char: "\u2661",
  },
  {
    code: ":dagger:",
    name: "Dolk",
    char: "\u2020",
  },
  {
    code: ":latin_cross:",
    name: "Kors",
    char: "\u271D",
  },
  {
    code: ":latin_cross-outline:",
    name: "Konturerat Kors",
    char: "\u271D",
  },
  {
    code: ":latin_cross-3d:",
    name: "Kors 3D",
    char: "\u271E",
  },
  {
    code: ":asterisk:",
    name: "Asterisk",
    char: "︎*",
  },
  {
    code: ":candle:",
    name: "Stearinljus",
    char: "\uD83D\uDD6F\uFE0F",
  },
  {
    code: ":paw_prints:",
    name: "Tassavtryck",
    char: "\uD83D\uDC3E",
  },
];

export default emoticons;
