import { memo, useCallback, useState, useMemo } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Stack,
  SxProps,
  Pagination,
} from "@mui/material";

import LabeledSpinner from "@/components/LabeledSpinner";
import useDebounce from "@/hooks/useDebounce";
import useSettings from "@/hooks/useSettings";
import { Nullish } from "@/utils/types";

import SearchBar from "@/extensions/klarahill/components/SearchBar";
import { Poem, PoemFilter, POEMS_PAGE_SIZE } from "@/extensions/obituary/types";
import usePoemsQuery from "@/extensions/obituary/queries/usePoemsQuery";

import PoemLibrary from "./PoemLibrary";

const styles: Record<string, SxProps> = {
  spinner: {
    minWidth: 550,
    height: "calc(90vh + 56px + 52.5px)",
  },

  pagination: {
    alignItems: "center",
    justifyContent: "center",
    p: 2,
    width: "100%",
    bgcolor: "grey.200",
  },
};

export interface PoemLibraryDialogProps
  extends Omit<DialogProps, "onClose" | "onSubmit" | "onChange"> {
  onClose: () => void;
  onSubmit: (poem: Poem) => void;
}

const PoemLibraryDialog: React.FC<PoemLibraryDialogProps> = ({
  onClose,
  open,
  onSubmit,
  ...props
}) => {
  const [selected, setSelected] = useState<Nullish<Poem>>();

  const { settings: filter, setSettings: setFilter } = useSettings<PoemFilter>(
    {},
  );

  const debouncedSetFilter = useDebounce(setFilter, 1000);

  const poems = usePoemsQuery(filter);
  const isLoading = poems.isLoading;

  const pages = useMemo(
    () => Math.floor((poems.data?.count ?? 0) / POEMS_PAGE_SIZE),
    [poems?.data?.count],
  );

  /// Callbacks

  const handleSubmit = useCallback(() => {
    if (selected == null) return;
    onSubmit(selected);
    onClose();
  }, [selected, onSubmit, onClose]);

  const handleSelectPoem = useCallback((poem: Poem) => setSelected(poem), []);

  const handleSearchChange = useCallback(
    (query: string) =>
      debouncedSetFilter({
        search: query === "" ? undefined : query,
        page: undefined,
      }),
    [debouncedSetFilter],
  );

  const handleSearchSubmit = useCallback(
    () => poems.refetch(),
    [poems.refetch],
  );

  const handlePagination = useCallback(
    (_: React.ChangeEvent<unknown>, page: number) => setFilter({ page }),
    [],
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.target instanceof HTMLInputElement) return;

      if (event.key === "Enter" && selected != null) {
        event.preventDefault();
        handleSubmit();
      }
    },
    [selected, handleSubmit],
  );

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={onClose}
      onKeyDown={handleKeyDown}
      {...props}
    >
      {isLoading ? (
        <LabeledSpinner label="Hämtar verser..." sx={styles.spinner} />
      ) : (
        <>
          <SearchBar
            onChange={handleSearchChange}
            onSubmit={handleSearchSubmit}
            placeholder="Sök efter textinnehåll eller författare"
            defaultValue={filter?.search}
          />

          {poems.isLoading || poems.data == null ? (
            <LabeledSpinner
              label="Hämtar dikter..."
              sx={{ ...styles.spinner, bgcolor: "grey.200" }}
            />
          ) : (
            <DialogContent dividers sx={{ p: 0, m: 0 }}>
              <PoemLibrary
                poems={poems?.data?.results ?? []}
                selected={selected}
                onSelectPoem={handleSelectPoem}
                {...props}
              />

              {pages > 1 && (
                <Stack sx={styles.pagination}>
                  <Pagination
                    onChange={handlePagination}
                    variant="outlined"
                    count={pages}
                    page={filter?.page ?? 1}
                    shape="rounded"
                  />
                </Stack>
              )}
            </DialogContent>
          )}

          <DialogActions>
            {onClose && <Button onClick={onClose}>Avbryt</Button>}
            <Button disabled={selected == null} onClick={handleSubmit}>
              Välj vers
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default memo(PoemLibraryDialog);
