import { memo } from "react";
import { Box, IconButton, IconButtonProps, Tooltip } from "@mui/material";

export interface ToolbarButtonProps extends IconButtonProps {
  icon: React.ReactNode;
  tooltip: string;
  onClick: () => void;
}

const ToolbarButton: React.FC<ToolbarButtonProps> = ({
  icon,
  tooltip,
  onClick,
  sx,
  ...props
}) => (
  <Box component="li">
    <Tooltip arrow title={tooltip} placement="top">
      <span>
        <IconButton
          disableRipple
          size="small"
          tabIndex={-1}
          sx={{
            transform: "scale(0.8)",
            transition: "background-color 200ms",
            "&:hover": { bgcolor: (theme) => theme.palette.action.hover },
            ...sx,
          }}
          onClick={onClick}
          {...props}
        >
          {icon}
        </IconButton>
      </span>
    </Tooltip>
  </Box>
);

export default memo(ToolbarButton);
