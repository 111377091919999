import React, { memo, useMemo } from "react";
import { Grow, Paper, SxProps } from "@mui/material";
import { ClickAwayListener, Unstable_Popup as Popup } from "@mui/base";

import { Emoticon } from "@/extensions/obituary/types";
import EmoticonButton from "./EmoticonButton";

const styles: Record<string, SxProps> = {
  root: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    maxWidth: 200,
    p: 1,
  },
};

export interface EmoticonPickerProps {
  anchor: React.RefObject<HTMLElement>;
  open: boolean;
  onClickAway: () => void;
  onEmoticonClick: (emoticon: Emoticon) => void;
  emoticons: Emoticon[];
}

const SymbolPicker: React.FC<EmoticonPickerProps> = ({
  anchor,
  emoticons,
  open,
  onClickAway,
  onEmoticonClick,
}) => {
  const renderedEmoticons = useMemo(() => {
    return emoticons.map((symbol) => (
      <EmoticonButton
        key={symbol.code}
        onEmoticonClick={onEmoticonClick}
        {...symbol}
      />
    ));
  }, [emoticons, onEmoticonClick]);

  return (
    <Popup
      open={open}
      anchor={anchor?.current}
      placement="bottom-end"
      offset={4}
    >
      <ClickAwayListener onClickAway={onClickAway}>
        <Grow in={open}>
          <Paper variant="outlined" sx={styles.root}>
            {renderedEmoticons}
          </Paper>
        </Grow>
      </ClickAwayListener>
    </Popup>
  );
};

export default memo(SymbolPicker);
