import { Box, Typography } from "@mui/material";

import LabeledValue from "@/components/LabeledValue";
import { Field, imgCSS, readFieldCSS } from "./shared";

export type ReadonlyFieldProps = {
  isCompact: boolean;
} & Field;

const ReadField: React.FC<ReadonlyFieldProps> = ({
  type,
  name,
  value,
  isCompact,
  ...props
}) => {
  switch (type) {
    case "autocomplete":
      return (
        <Box sx={readFieldCSS(isCompact)}>
          <Typography variant="subtitle2" sx={{ color: "grey.600" }}>
            {name}
          </Typography>

          <Typography variant={isCompact ? "body2" : "body1"}>
            {Array.isArray(value) && value.length !== 0
              ? value.map(({ label }) => label).join(", ")
              : `Förvalda ${name}`}
          </Typography>
        </Box>
      );

    case "portrait":
      return (
        <Box sx={readFieldCSS(isCompact)}>
          <Typography variant="subtitle2" sx={{ color: "grey.600" }}>
            {name}
          </Typography>
          <Box
            sx={{
              width: 140,
              height: 140,
              borderRadius: 100,
              "> img": { ...imgCSS, borderRadius: 100 },
            }}
          >
            {typeof value === "string" ? (
              <img style={{ aspectRatio: "1/1" }} src={value} />
            ) : (
              <Typography>Bild saknas</Typography>
            )}
          </Box>
        </Box>
      );

    case "image":
    case "obituary":
      return (
        <Box sx={{ ...readFieldCSS(isCompact) }}>
          <Typography variant="subtitle2" sx={{ color: "grey.600" }}>
            {name}
          </Typography>
          <Box sx={{ "> img": imgCSS }}>
            {typeof value === "string" ? (
              <img src={value} />
            ) : (
              <Typography>Bild saknas</Typography>
            )}
          </Box>
        </Box>
      );

    case "date":
    case "number":
    case "text": {
      const isMultiline = ("multiline" in props && props.multiline) ?? false;
      let hasValue = value != null;
      if (typeof value === "string") hasValue = value.trim().length !== 0;

      if (type === "date" && hasValue && typeof value !== "boolean") {
        const date = typeof value === "string" ? new Date(value) : value;
        if (date) value = (date as Date).toISOString().substring(0, 10);
      }

      return (
        <LabeledValue
          label={name}
          value={hasValue ? (value as string) : "—"}
          compact={isCompact}
          multiline={isMultiline}
        />
      );
    }

    case "switch":
      return (
        <Box sx={readFieldCSS(isCompact)}>
          <Typography variant="subtitle2" sx={{ color: "grey.600" }}>
            {name}
          </Typography>
          <Typography variant={isCompact ? "body2" : "body1"}>
            {value
              ? // @ts-ignore
                props.on ?? "Ja"
              : // @ts-ignore
                props.off ?? "Nej"}
          </Typography>
        </Box>
      );

    default:
      throw new Error(`Unknown type: ${type}.`);
  }
};

export default ReadField;
