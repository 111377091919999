import { memo } from "react";
import {
  Box,
  CircularProgress,
  CircularProgressProps,
  Stack,
  StackProps,
  SxProps,
  Typography,
} from "@mui/material";

interface LabeledSpinnerProps extends StackProps {
  backdrop?: boolean;
  label: string;
  spinnerProps?: CircularProgressProps;
  sx?: SxProps;
}

const LabeledSpinner: React.FC<LabeledSpinnerProps> = ({
  backdrop,
  label,
  spinnerProps,
  sx,
  ...props
}) => (
  <Stack
    gap={4}
    display="flex"
    justifyContent="center"
    alignItems="center"
    p={4}
    sx={{
      ...(backdrop && {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 10,
        backgroundColor: "rgba(255, 255, 255, 0.5)",
      }),
      ...sx,
    }}
    {...props}
  >
    {backdrop && (
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          bgcolor: "rgba(255, 255, 255, 0.5)",
          backdropFilter: "blur(2px)",
        }}
      />
    )}
    <CircularProgress {...spinnerProps} />

    <Typography
      variant="subtitle2"
      sx={{
        bgcolor: "background.paper",
        borderRadius: 1,
        padding: 1,
        zIndex: 1,
      }}
    >
      {label}
    </Typography>
  </Stack>
);

export default memo(LabeledSpinner);
