import { useState } from "react";
import * as Bananas from "bananas-commerce-admin";
import { useSnackbar } from "notistack";
import { Box, Switch, Typography } from "@mui/material";

import type { Comment } from "@/extensions/klarahill/types";

import Field from "./Field";

const stringOrFallback = (s: string, fallback: string) =>
  s.trim() === "" ? fallback : s;

export interface CommentProps {
  comment: Comment;
}

const Comment: React.FC<CommentProps> = ({
  comment: { author, text, date_created, email, id, status: commentStatus },
}) => {
  const [status, setStatus] = useState(commentStatus);
  const dateCreated = new Date(date_created);
  const { enqueueSnackbar } = useSnackbar();
  const api = Bananas.useApi();

  const updateStatus = async () => {
    const action = api.operations["case.case:set-comment-status"];
    if (!action)
      throw new Error('Invalid action "case.case:set-comment-status".');

    const newStatus = status === "published" ? "removed" : "published";

    const response = await action.call({
      params: { comment_id: id },
      body: { status: newStatus },
    });

    if (response.ok) {
      enqueueSnackbar("Status på kommentar uppdaterat.");
      setStatus(newStatus);
    } else {
      enqueueSnackbar("Fel vid uppdaterande av kommentarstatus.", {
        variant: "error",
      });
      throw response;
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 2,
        }}
      >
        <Field label="Namn" value={author} />
        <Box>
          <Typography
            variant="body1"
            component="label"
            htmlFor="visibilitySwitch"
          >
            Synlig
          </Typography>
          <Switch
            checked={status === "published"}
            onChange={updateStatus}
            name="visibilitySwitch"
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
          "> *": {
            flex: 1,
          },
        }}
      >
        <Field label="E-post" value={stringOrFallback(email, "–")} />
        <Field label="Upplagt den" value={dateCreated.toLocaleDateString()} />
      </Box>
      <Field label="Text" value={text} />
    </Box>
  );
};

export default Comment;
