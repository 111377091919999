import { useState } from "react";
import { Box, Typography } from "@mui/material";
import * as Bananas from "bananas-commerce-admin";

import { Card } from "@/extensions/klarahill/components/Card";
import type { Case } from "@/extensions/klarahill/types";
import * as styles from "@/extensions/klarahill/pages/case/shared";

import ObituaryCard from "./ObituaryCard";

export const Memorial: React.FC<styles.CaseTabProps> = ({ case: c }) => {
  const [memorial, setMemorial] = useState(c.memorial);
  const api = Bananas.useApi();

  return (
    <Box sx={styles.content}>
      <Box sx={styles.cards}>
        <Box sx={styles.primaryCards}>
          <Card
            isEditable
            title="Presentation"
            layout={[
              [
                {
                  name: "Porträtt",
                  type: "portrait",
                  value: memorial.image,
                  formName: "image",
                },
                {
                  name: "Omslag",
                  type: "image",
                  value: memorial.header_image,
                  formName: "header_image",
                },
              ],
              [
                {
                  name: "Hälsning",
                  type: "text",
                  value: memorial.text,
                  formName: "text",
                  helperText:
                    'En kortare hälsning som visas innan namnet på minnessidor och i listningen. Ungefär fem ord. För information om begravningen eller minnesstunden redigera respektive informationsfält på "Ärende" sidan.',
                  maxLength: 40,
                },
              ],
            ]}
            onSubmit={async (values, form) => {
              const action = api.operations["case.case:update-memorial"];
              if (!action)
                throw new Error('Invalid action "case.case:update-memorial".');

              const greeting = values.text as string;
              const portraitImage = values.image as File;
              const headerImage = values.header_image as File;

              if (greeting.length > 0 && greeting !== memorial.text) {
                const response = await action.call({
                  params: { memorial_id: memorial.id },
                  body: {
                    text: greeting,
                    // Only update the `text` field.
                    marked_as_paid_at: memorial.marked_as_paid_at,
                    published_at: memorial.published_at,
                  },
                });

                if (response.ok) {
                  const updatedMemorial: Case["memorial"] =
                    await response.json();
                  setMemorial(updatedMemorial);
                } else {
                  console.error(response);
                  throw new Error("uppdaterande av hälsning");
                }
              }

              if (portraitImage.size > 0 || headerImage.size > 0) {
                const action =
                  api.operations["case.case:upload-memorial-images"];
                if (!action)
                  throw new Error(
                    'Invalid action "case.case:upload-memorial-images".',
                  );

                const formData = new FormData(form);
                formData.delete("text");

                const response = await action.call({
                  params: { memorial_id: memorial.id },
                  body: formData,
                });

                if (response.ok) {
                  const updatedMemorial: Case["memorial"] =
                    await response.json();
                  setMemorial(updatedMemorial);
                } else {
                  console.error(response);
                  throw new Error("uppladdning av minnesrumsbilder");
                }
              }

              return "Presentation uppdaterad.";
            }}
          />

          <ObituaryCard memorial={memorial} setMemorial={setMemorial} />

          <Typography sx={{ color: "grey.600" }} variant="body2">
            Information om begravningsärendet hämtas från Bitnet. Gå till Bitnet
            för att kunna ändra någon inställningar för begravnings, minnestund
            eller gåva.
          </Typography>
        </Box>

        <Box sx={styles.secondaryCards}>
          <Card
            isEditable
            isCompact
            title="Inställningar"
            tooltip="Obetalda minnesrum avpubliceras automatiskt 30 dagar efter att begravning har ägt rumt. Betalda minnesrum avpubliceras aldrig."
            layout={[
              [
                {
                  name: "Betalt",
                  formName: "marked_as_paid_at",
                  type: "switch",
                  value: Boolean(memorial.marked_as_paid_at),
                },
              ],
              [
                {
                  name: "Publikationsdatum",
                  formName: "published_at",
                  type: "date",
                  value: memorial.published_at,
                },
              ],
            ]}
            onSubmit={async (values) => {
              const action = api.operations["case.case:update-memorial"];
              if (!action)
                throw new Error('Invalid action "case.case:update-memorial".');

              const markedAsPaidAt = values["marked_as_paid_at"] === "on";
              const publishedAt = values["published_at"] as string;
              const body: Partial<
                Pick<Case["memorial"], "marked_as_paid_at" | "published_at">
              > = {};

              if (markedAsPaidAt && !memorial.marked_as_paid_at) {
                // Only update the date if we did not have a date before.
                body.marked_as_paid_at = new Date().toISOString();
              } else {
                body.marked_as_paid_at = null;
              }

              if (publishedAt && publishedAt.length > 0) {
                const date = new Date(publishedAt);
                body.published_at = date.toISOString();
              }

              if (Object.keys(body).length === 0) return null;

              const response = await action.call({
                params: { memorial_id: memorial.id },
                body,
              });

              if (response.ok) {
                const updatedMemorial: Case["memorial"] = await response.json();
                setMemorial(updatedMemorial);
              } else {
                console.error(response);
                throw new Error("uppdatering av hälsning");
              }

              return "Inställningar uppdaterade.";
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};
