import { useEffect, useState } from "react";

export default function useSessionStorage<T>(key: string, defaultValue: T) {
  const [state, setState] = useState<T>(defaultValue);
  const [mounted, setMounted] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (mounted) return;

    if (sessionStorage) {
      const value = getState<T>(key);
      if (value) setState(value);
      setLoaded(true);
      setMounted(true);
    }
  }, [key, mounted]);

  useEffect(() => {
    const event = (e: StorageEvent) => {
      if (e.storageArea !== sessionStorage) return;
      if (e.key === null) {
        // The clear method was called, go back to default value
        setState(defaultValue);
        return;
      }

      if (e.key === key && e.newValue !== e.oldValue) {
        setState(tryJSONParse<T>(e.newValue) ?? state);
      }
    };

    window.addEventListener("storage", event);
    return () => window.removeEventListener("storage", event);
  }, [key, defaultValue, state]);

  useEffect(() => {
    if (mounted) setLocalState(key, state);
  }, [state, key, mounted]);

  return [state, setState, loaded] as const;
}

function getState<T>(key: string): T | null {
  return tryJSONParse<T>(sessionStorage.getItem(key));
}

function setLocalState<T>(key: string, state: T) {
  sessionStorage.setItem(key, JSON.stringify(state));
}

function tryJSONParse<T>(json: string | null | undefined): T | null {
  if (!json) return null;
  try {
    return JSON.parse(json) as T;
  } catch {
    return null;
  }
}
