import { useQuery } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import * as Bananas from "bananas-commerce-admin";

import {
  PoemFilter,
  PoemListResponse,
  POEMS_PAGE_SIZE,
} from "@/extensions/obituary/types";
import * as object from "@/utils/object";

const usePoemsQuery = (filter: PoemFilter = {}) => {
  const api = Bananas.useApi();
  const { enqueueSnackbar } = useSnackbar();

  const options = useQuery({
    queryKey: ["obituary", "poems", filter],
    queryFn: async () => {
      const action = api.operations["obituary.poem:list"];
      if (!action) throw new Error('Invalid action "obituary.poem:list".');

      const belowThreshold = filter.search != null && filter.search.length < 3;
      if (belowThreshold) filter.search = undefined;

      const response = await action.call({
        query: object.omitNullish({
          page_size: POEMS_PAGE_SIZE,
          ...filter,
        }),
      });

      if (response.ok) {
        const poems: PoemListResponse = await response.json();
        return poems;
      } else {
        enqueueSnackbar("Kunde inte hämta verser.", {
          variant: "error",
        });
        console.error(response.statusText);
        return null;
      }
    },
  });

  return options;
};

export default usePoemsQuery;
