import AddCardOutlinedIcon from "@mui/icons-material/AddCardOutlined";
import CreditScoreOutlinedIcon from "@mui/icons-material/CreditScoreOutlined";

import Badge from "./Badge";

type OrderStatus = "created" | "captured";

const statusIcon: Record<OrderStatus, React.FC<{ fontSize: "small" }>> = {
  created: AddCardOutlinedIcon,
  captured: CreditScoreOutlinedIcon,
} as const;

const statusColor: Record<OrderStatus, Readonly<[string, string]>> = {
  created: ["#BBDEFB", "#1976D2"],
  captured: ["#C8E6C9", "#1B5E20"],
} as const;

export const OrderStatusBadge = ({ type }: { type: OrderStatus }) => {
  const [background, foreground] = statusColor[type];
  const Icon = statusIcon[type];

  return (
    <Badge
      backgroundColor={background}
      foregroundColor={foreground}
      icon={<Icon fontSize="small" />}
    />
  );
};
