import { useState } from "react";
import { Box, Button, Stack } from "@mui/material";
import { useSnackbar } from "notistack";
import * as Bananas from "bananas-commerce-admin";

import MailOutlineIcon from "@mui/icons-material/MailOutline";
import SimCardDownloadOutlinedIcon from "@mui/icons-material/SimCardDownloadOutlined";
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";
import Groups2OutlinedIcon from "@mui/icons-material/Groups2Outlined";
import HourglassEmptyRoundedIcon from "@mui/icons-material/HourglassEmptyRounded";

import { Card } from "@/extensions/klarahill/components/Card";
import * as styles from "@/extensions/klarahill/pages/case/shared";
import { Case, MemorialAttendee } from "@/extensions/klarahill/types";
import * as format from "@/utils/format";

import Attendees from "./Attendees";
import AttendeeDialog from "./AttendeeDialog";
import SendEmailDialog from "./SendEmailDialog";

export const Reception: React.FC<styles.CaseTabProps> = ({ case: c }) => {
  const openDialog = Bananas.useDialog();
  const { enqueueSnackbar } = useSnackbar();

  const [reception, setReception] = useState(c.reception);
  const [attendees, setAttendees] = useState(c.memorial_service_attendees);
  const [memorialSericeMessage, setMemorialServiceMessage] = useState(
    c.memorial.memorial_service_message,
  );

  const api = Bananas.useApi();

  const mainContact = c.customers?.find(
    ({ is_main_contact }) => is_main_contact,
  );

  const downloadAttendeesURL = api.operations[
    "case.case:get-memorial-service-pdf"
  ]?.url({
    params: { case_id: c.id },
  });

  const openAttendeesEmailDialog = async () => {
    const action =
      api.operations["case.case:send-memorial-service-attendees-email"];
    if (!action)
      throw new Error(
        'Invalid action "case.case:send-memorial-service-attendees-email".',
      );

    let recipient = mainContact!.email;
    if (
      await openDialog(
        "Skicka gästlista",
        <SendEmailDialog
          mainContact={mainContact!}
          onChange={(value) => (recipient = value)}
        />,
        {
          ok: "Skicka",
          cancel: "Avbryt",
          dialogProps: { fullWidth: true, maxWidth: "sm" },
        },
      )
    ) {
      const response = await action.call({
        params: { case_id: c.id },
        body: { recipient },
      });
      if (response.ok) {
        enqueueSnackbar(
          `Ett mail med gästlistan skickades till ${recipient}.`,
          {
            variant: "success",
          },
        );
      } else {
        enqueueSnackbar(
          `Misslyckades med att skicka gästlista till ${recipient}.`,
          {
            variant: "error",
          },
        );
        throw response;
      }
    }
  };

  const openEditAttendeeDialog = async (id: number) => {
    const action = api.operations["case.case:update-reception"];
    if (!action)
      throw new Error('Invalid action "case.case:update-reception".');

    let attendee = attendees.find((attendee) => attendee.id === id);
    if (!attendee) throw new Error("Attendee not found.");
    let changes;
    if (
      await openDialog(
        `Ändra ${attendee.name}`,
        <AttendeeDialog
          type="change"
          attendee={attendee}
          onChange={(value) => {
            changes = { ...value, id: attendee!.id };
            attendee = { ...attendee!, ...value };
          }}
        />,
        {
          ok: "Spara",
          cancel: "Avbryt",
          dialogProps: { fullWidth: true, maxWidth: "md" },
        },
      )
    ) {
      if (changes == null) return;

      const response = await action.call({
        params: { case_id: c.id },
        body: { attendees: [changes] },
      });

      if (response.ok) {
        const updatedReception: Case["reception"] = await response.json();
        setReception(updatedReception);

        const newAttendees = structuredClone(attendees);
        const index = newAttendees.findIndex((attendee) => attendee.id === id);
        newAttendees[index] = attendee;
        setAttendees(newAttendees);
        enqueueSnackbar(
          `Uppdaterade ${format.genetive(attendee.name)} minnestundsanmälan.`,
          {
            variant: "success",
          },
        );
      } else {
        enqueueSnackbar(
          `Misslyckades med att uppdatera ${format.genetive(attendee.name)} minnestundsanmälan.`,
          { variant: "error" },
        );
        throw response;
      }
    }
  };

  const openCreateAttendeeDialog = async () => {
    const action = api.operations["case.case:create-attendee"];
    if (!action) throw new Error('Invalid action "case.case:create-attendee".');

    let attendee;
    if (
      await openDialog(
        "Skapa ny gäst till minnesstund",
        <AttendeeDialog
          type="create"
          onChange={(value) => {
            attendee = value;
          }}
        />,
        {
          ok: "Skapa",
          cancel: "Avbryt",
          dialogProps: { fullWidth: true, maxWidth: "md" },
        },
      )
    ) {
      if (attendee == null) return;

      const response = await action.call({
        params: { case_id: c.id },
        body: attendee,
      });

      if (response.ok) {
        const updatedAttendees: MemorialAttendee[] = await response.json();
        setAttendees(updatedAttendees);

        enqueueSnackbar(`Skapade minnesstundsanmälan.`, {
          variant: "success",
        });
      } else {
        enqueueSnackbar(`Misslyckades med att skapa minnestundsanmälan.`, {
          variant: "error",
        });
        throw response;
      }
    }
  };

  const makeReserveToGuest = async (id: number) => {
    const action = api.operations["case.case:update-reception"];
    if (!action)
      throw new Error('Invalid action "case.case:update-reception".');

    const index = attendees.findIndex((v) => v.id === id);
    const attendee = attendees?.[index];
    if (attendee == null) throw new Error("Attendee not found.");

    const response = await action.call({
      params: { case_id: c.id },
      body: { attendees: [{ id, reserve: false }] },
    });

    if (response.ok) {
      const updatedReception: Case["reception"] = await response.json();
      setReception(updatedReception);

      const newAttendees = structuredClone(attendees);
      newAttendees[index]!.reserve = false;
      setAttendees(newAttendees);
      enqueueSnackbar(
        `Uppdaterade synligheten för ${format.genetive(attendee.name)} minnestundsanmälan.`,
        { variant: "success" },
      );
    } else {
      enqueueSnackbar(
        `Misslyckades med att uppdatera synligheten för ${format.genetive(
          attendee.name,
        )} minnestundsanmälan.`,
        { variant: "error" },
      );
      throw response;
    }
  };

  const deleteAttendee = async (id: number) => {
    const action = api.operations["case.attendee:delete"];
    if (!action) throw new Error('Invalid action "case.case:delete-attendee".');

    const index = attendees.findIndex((v) => v.id === id);
    const attendee = structuredClone(attendees[index]);
    if (attendee == null) throw new Error("Attendee not found.");

    const response = await action.call({
      params: { case_id: c.id, attendee_id: id },
    });

    if (response.ok) {
      const newAttendees = structuredClone(attendees);
      delete newAttendees[index];
      setAttendees(newAttendees);
      enqueueSnackbar(
        `Tog bort för ${format.genetive(attendee.name)} minnestundsanmälan.`,
        {
          variant: "success",
        },
      );
    } else {
      enqueueSnackbar(
        `Misslyckades med att uppdatera synligheten för ${format.genetive(
          attendee.name,
        )} minnestundsanmälan.`,
        {
          variant: "error",
        },
      );
      throw response;
    }
  };

  return (
    <>
      <Box sx={styles.content}>
        <Box sx={styles.cards}>
          <Box sx={styles.rowContainer}>
            <Box sx={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <Box
                sx={{ ...styles.primaryCards, "> *": { minHeight: "100%" } }}
              >
                <Card
                  isEditable
                  onSubmit={async (values) => {
                    const action = api.operations["case.case:update-memorial"];
                    if (!action)
                      throw new Error(
                        'Invalid action "case.case:update-memorial".',
                      );

                    const text = values.text as string;

                    if (memorialSericeMessage !== text) {
                      const response = await action.call({
                        params: { memorial_id: c.memorial.id },
                        body: {
                          memorial_service_message: text,
                        },
                      });

                      if (response.ok) {
                        const updatedMemorial: Case["memorial"] =
                          await response.json();
                        setMemorialServiceMessage(
                          updatedMemorial.memorial_service_message,
                        );
                      } else {
                        console.error(response);
                        throw new Error(
                          "uppdaterande av leverantörsmeddelande.",
                        );
                      }
                    }

                    return "Meddelandet till leverantör uppdaterad.";
                  }}
                  title="Minnesstund"
                  layout={[
                    [
                      {
                        name: "Meddelande till leverantör",
                        type: "text",
                        multiline: true,
                        value: memorialSericeMessage,
                        formName: "text",
                        minRows: 2,
                      },
                    ],
                  ]}
                />
              </Box>

              <Box sx={styles.secondaryCards}>
                <Card
                  title="Information"
                  isCompact
                  isEditable
                  layout={[
                    [
                      {
                        name: "Synlighet",
                        formName: "visibility",
                        type: "switch",
                        value: reception?.visibility ?? false,
                        helperText: "Visa minnesstunden på minnessidan.",
                      },
                    ],
                    [
                      {
                        name: "Information",
                        formName: "information",
                        type: "text",
                        multiline: true,
                        value: reception?.information,
                        helperText:
                          'Fritextfält för information gällande minnesstunden som visas på minnessidan. Exempelvis: "Efter akten inbjudes till minnesstund, anmäl senast 12 feb."',
                        minRows: 2,
                      },
                    ],
                    [
                      {
                        name: "Plats",
                        type: "text",
                        value: reception?.location_name,
                        disabled: true,
                      },
                    ],
                    [
                      {
                        name: "Max antal gäster",
                        formName: "max_attendees",
                        type: "number",
                        value: reception?.max_attendees,
                        min: 1,
                        helperText:
                          "Max antal gäster som kan anmäla sig till minnesstunden. Lämna tomt för obegränsat antal gäster.",
                      },
                    ],
                    [
                      {
                        name: "När",
                        type: "date",
                        value: c.funeral?.funeral_at,
                        disabled: true,
                      },
                    ],
                    [
                      {
                        name: "Sista anm.",
                        formName: "rsvp_date",
                        type: "date",
                        value: reception?.rsvp_date,
                      },
                    ],
                  ]}
                  onSubmit={async (values) => {
                    const action = api.operations["case.case:update-reception"];
                    if (!action)
                      throw new Error(
                        'Invalid action "case.case:update-reception".',
                      );

                    const visibility = values["visibility"] === "on";
                    const information = values["information"] as string;
                    const rsvp_date = values["rsvp_date"] as string;
                    const max_attendees =
                      values["max_attendees"] === ""
                        ? undefined
                        : parseInt(values["max_attendees"] as string);

                    const response = await action.call({
                      params: { case_id: c.id },
                      body: {
                        visibility,
                        information,
                        rsvp_date,
                        max_attendees,
                      },
                    });

                    if (response.ok) {
                      const updatedReception: Case["reception"] =
                        await response.json();
                      setReception(updatedReception);
                    } else {
                      console.error(response);
                      throw new Error("uppdatering av minnesstund");
                    }

                    return "Inställningar uppdaterade.";
                  }}
                />
              </Box>
            </Box>

            <Attendees
              type="guest"
              icon={<Groups2OutlinedIcon />}
              header="Gästlista"
              attendees={attendees.filter((v) => !v.reserve)}
              onDelete={deleteAttendee}
              onEdit={openEditAttendeeDialog}
            />

            <Attendees
              type="reserve"
              icon={<HourglassEmptyRoundedIcon />}
              header="Reservlista"
              attendees={attendees.filter((v) => v.reserve)}
              onDelete={deleteAttendee}
              onMakeGuest={makeReserveToGuest}
              onEdit={openEditAttendeeDialog}
            />
          </Box>
        </Box>
      </Box>

      <Bananas.ActionBar>
        <Stack direction="row" sx={styles.actionBar}>
          <Button
            variant="outlined"
            startIcon={<PersonAddOutlinedIcon />}
            onClick={openCreateAttendeeDialog}
          >
            Skapa gäst
          </Button>

          {downloadAttendeesURL && (
            <Button
              variant="outlined"
              startIcon={<SimCardDownloadOutlinedIcon />}
              href={downloadAttendeesURL.href}
              target="_blank"
            >
              Ladda ned gästlista
            </Button>
          )}

          <Button
            variant="outlined"
            startIcon={<MailOutlineIcon />}
            onClick={openAttendeesEmailDialog}
          >
            Skicka gästlista
          </Button>
        </Stack>
      </Bananas.ActionBar>
    </>
  );
};
