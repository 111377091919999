import { memo, useCallback, useEffect, useMemo, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Stack,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { Dayjs } from "dayjs";
import { omit } from "typedash/omit";
import { useSnackbar } from "notistack";
import * as Bananas from "bananas-commerce-admin";

import AddIcon from "@mui/icons-material/Add";

import Select from "@/components/Select";
import LabeledSpinner from "@/components/LabeledSpinner";
import useSettings from "@/hooks/useSettings";
import { Nullish, Option } from "@/utils/types";

import { Case } from "@/extensions/klarahill/types";
import * as story from "@/extensions/obituary/pages/detail/Story";
import {
  Abbreviation,
  Ad,
  CreateAdPayload,
  Layout,
} from "@/extensions/obituary/types";
import { Story } from "@/extensions/obituary/pages/detail/Story";
import * as convert from "@/extensions/obituary/pages/detail/Story/convert";
import usePublicationsQuery from "@/extensions/obituary/queries/usePublicationsQuery";
import useDeadlinesQuery from "@/extensions/obituary/queries/useDeadlinesQuery";
import adName from "@/extensions/obituary/utils/adName";

interface Settings {
  layout: Nullish<Layout>;
  publication: Nullish<Abbreviation>;
  publicationDate: Nullish<Dayjs>;
  editingDeadline: Nullish<Dayjs>;
  story: Nullish<Story>;
}

export interface CreateAdProps {
  ads: Ad[];
  c: Case;
  onSubmit: (adResponse: Ad) => void;
  onClose?: () => void;
}

const CreateAd: React.FC<CreateAdProps> = ({ ads, c, onClose, onSubmit }) => {
  const api = Bananas.useApi();
  const { enqueueSnackbar } = useSnackbar();

  const [hasSubmitted, setHasSubmitted] = useState(false);

  const { settings, setSettings } = useSettings<Settings>({
    layout: undefined,
    publication: undefined,
    publicationDate: undefined,
    editingDeadline: undefined,
    story: undefined,
  });

  const publications = usePublicationsQuery(settings.publication);
  const deadlines = useDeadlinesQuery(settings.publication, settings.layout);

  const canSubmit = useMemo(() => {
    const withoutDate = omit(settings, ["publicationDate", "editingDeadline"]);
    return Object.values(withoutDate).every(Boolean);
  }, [settings]);

  const templates = useMemo(() => {
    const templates: Option<Story>[] = [];

    for (const ad of ads) {
      templates.push({ label: adName(ad), value: ad.story });
    }

    const defaultTemplate = story.template[settings.layout!];
    if (defaultTemplate != null) {
      templates.push({
        label: "Data från begravningsärendet",
        value: defaultTemplate(c),
      });
    }

    return templates;
  }, [c, settings.layout]);

  // Set preselected story. Defaults to case data (if no ads) or the latest ad (if exists).
  useEffect(() => {
    const preselectedStory = templates[0]?.value;
    if (settings.story == null && preselectedStory != null) {
      setSettings({ story: preselectedStory });
    }
  }, [templates]);

  /// Callbacks

  const handleGetOptionLabel = useCallback(
    ({ disabled, label }: Option) =>
      disabled ? `${label} (ej tillgänglig)` : label,
    [],
  );

  const handleGetOptionDisabled = useCallback(
    ({ disabled }: Option) => disabled ?? true,
    [],
  );

  const handleSelectPublication = useCallback(
    (_: React.SyntheticEvent<Element, Event>, option: Nullish<Option>) => {
      if (option == null) {
        enqueueSnackbar("Invalid publication.", { variant: "error" });
        return;
      }

      if (publications.data == null) return;

      const publication = option.value as Abbreviation;
      if (!publications.data.some((p) => p.abbreviation === publication)) {
        enqueueSnackbar("Ogiltig publikation.", { variant: "error" });
      }

      setSettings({ publication });
    },
    [publications],
  );

  const handleSelectDate = useCallback(
    (newDate: Nullish<Dayjs>) => {
      if (newDate == null) return;

      const newDeadline = deadlines.data?.find((d) =>
        newDate.isSame(d.publicationDate),
      );

      if (newDeadline == null) {
        enqueueSnackbar("Det går inte att välja detta datum.", {
          variant: "error",
        });
        return;
      }

      setSettings({
        publicationDate: newDeadline.publicationDate,
        editingDeadline: newDeadline.deadlineTime,
      });
    },
    [deadlines.data],
  );

  const handleSubmit = useCallback(async () => {
    if (hasSubmitted) return;
    setHasSubmitted(true);

    if (!canSubmit) {
      enqueueSnackbar("Fyll i alla fält.", { variant: "error" });
      setHasSubmitted(false);
      return;
    }

    const action = api.operations["obituary.ad:create"];
    if (action == null) {
      setHasSubmitted(false);
      throw new Error('Invalid action "obituary.ad:create"');
    }

    let story = settings.story!;
    // Convert two-column layouts to one-column layouts
    if (settings.layout === "OBITUARY_ONE_COLUMN") {
      story = convert.twoColumnToOneColumn(story);
    }

    const payload: CreateAdPayload = {
      case_id: c.id,
      document: {
        story,
        layout: settings.layout!,
        publication: settings.publication!,
      },
    };

    if (settings.publicationDate != null && settings.editingDeadline != null) {
      payload.deadline = {
        publication_date: settings.publicationDate.format("YYYY-MM-DD"),
        editing_deadline: settings.editingDeadline.toISOString(),
      };
    }

    const response = await action.call({ body: payload });
    if (response.ok) {
      const data = (await response.json()) as Ad;
      onSubmit(data);
      setHasSubmitted(false);
    } else {
      enqueueSnackbar(
        "Fel vid skapande av annons. Försök igen eller kontakta support om felet kvarstår.",
        { variant: "error" },
      );
      setHasSubmitted(false);
    }
  }, [c, settings]);

  return !publications.hasAllData ? (
    <LabeledSpinner
      label="Hämtar publikationer..."
      sx={{ minWidth: 550, minHeight: 440 }}
    />
  ) : (
    <Box
      component="article"
      sx={{ position: "relative", minWidth: 550, minHeight: 370 }}
    >
      {hasSubmitted && <LabeledSpinner backdrop label="Skapar annons..." />}

      <DialogTitle>Skapa en ny annons</DialogTitle>

      <DialogContent>
        <Stack gap={2} pt={1}>
          <Autocomplete
            fullWidth
            disablePortal
            ListboxProps={{ sx: { maxHeight: 240 } }}
            options={publications.options ?? []}
            getOptionLabel={handleGetOptionLabel}
            getOptionDisabled={handleGetOptionDisabled}
            onChange={handleSelectPublication}
            renderInput={(params) => (
              <TextField {...params} label="Publikation" />
            )}
          />

          <Select
            label="Mall"
            disabled={settings.publication == null}
            options={publications.layoutOptions ?? []}
            value={settings.layout}
            onChange={({ target }) =>
              setSettings({ layout: target.value as Layout })
            }
          />

          {templates.length > 1 && (
            <Select
              label="Utgå från"
              disabled={settings.layout == null}
              options={templates}
              value={settings.story}
              onChange={({ target }) =>
                setSettings({ story: target.value as Story })
              }
            />
          )}

          <FormControl fullWidth>
            <DatePicker
              label="Införandedatum (frivilligt)"
              defaultValue={
                settings.publicationDate ?? deadlines.firstAvaliableDate
              }
              value={settings.publicationDate}
              onChange={handleSelectDate}
              shouldDisableDate={deadlines.handleShouldDisableDate}
              format="YYYY-MM-DD"
            />
            <FormHelperText>
              Det går enbart att välja lediga införandedatum. Du kan ändra datum
              innan du skickar in.
            </FormHelperText>
          </FormControl>
        </Stack>
      </DialogContent>

      <DialogActions>
        {onClose && <Button onClick={onClose}>Avbryt</Button>}
        <Button
          disabled={hasSubmitted}
          startIcon={<AddIcon />}
          onClick={handleSubmit}
        >
          Skapa annons
        </Button>
      </DialogActions>
    </Box>
  );
};

export default memo(CreateAd);
