import { Container, isContainer } from "./Item/Container";
import { isSingleline, makeSingleline, Variant } from "./Item/Singleline";
import { isRow } from "./Item/Row";
import { Story } from "./shared";

/**
 * Convert two column obituary to one column layout.
 * Replaces `relatives` rows with singlelines.
 */
export function twoColumnToOneColumn(story: Story): Story {
  const newStory = [...story];

  const relativesIndex = newStory.findIndex(
    (item) => isContainer(item) && item.variant === "relatives",
  );

  if (relativesIndex == null) {
    console.error(
      "Unable to find 'relatives' in story. Either the story has an issue, or the template has changed to the degree that the convertion function should be revised.",
    );
  } else {
    const newRelatives = { ...newStory[relativesIndex] } as Container;

    // Convert all rows to singlelines
    const convertedChildren = newRelatives.children.map((child) => {
      if (isRow(child)) {
        const text = child.children
          .filter(isSingleline)
          .map(({ text }) => text)
          .join(" ");

        const variant = (child.children[0]?.variant ?? "default") as Variant;
        return makeSingleline({ text, variant });
      } else {
        return child;
      }
    });

    newRelatives.children = convertedChildren;
    newStory[relativesIndex] = newRelatives;
  }

  return newStory;
}
