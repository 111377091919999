export type Key = string | number;
export type Path = Key[];

/**
 * Removes properties with nullish keys from `object`.
 */
export function omitNullish<T extends Record<string, unknown>>(
  object: T,
): { [K in keyof T]: NonNullable<T[K]> } {
  return Object.entries(object).reduce(
    (result, [key, value]) => {
      if (value != null) {
        result[key as keyof T] = value as unknown as NonNullable<T[keyof T]>;
      }
      return result;
    },
    {} as { [K in keyof T]: NonNullable<T[K]> },
  );
}
