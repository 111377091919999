import { Button, TableCell, TableRow, Typography } from "@mui/material";
import SaveAltOutlinedIcon from "@mui/icons-material/SaveAltOutlined";

import { GiftStatusBadge } from "@/extensions/klarahill/components/GiftStatusBadge";
import { Gift } from "@/extensions/klarahill/types";

import { Nullish } from "@/utils/types";

export interface GiftWithCertificateURL extends Gift {
  certificateUrl?: Nullish<URL>;
}

const Row = ({
  gift: {
    first_name,
    last_name,
    causes,
    certificateUrl,
    status,
    certificate_download_date,
  },
  onClick,
}: {
  gift: GiftWithCertificateURL;
  onClick?: () => void;
}) => {
  if (causes.length === 0) throw new Error("No casues for case.");

  const isNew = certificate_download_date == null;

  return (
    <TableRow>
      <TableCell>
        <GiftStatusBadge type={status} />
      </TableCell>

      <TableCell>
        <Typography variant="body2">
          {first_name} {last_name}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography variant="body2">{causes[0]!.name}</Typography>
      </TableCell>

      <TableCell sx={{ paddingRight: 3 }} align="right">
        {certificateUrl != null ? (
          <Button
            target="_blank"
            href={String(certificateUrl)}
            onClick={onClick}
            color={isNew ? "primary" : "secondary"}
            variant={isNew ? "text" : "outlined"}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: 1,
              paddingY: 0.25,
              paddingX: 1,
              minWidth: "unset",
              width: "fit-content",
              marginLeft: "auto",
              ...(isNew
                ? {
                    color: "white",
                    bgcolor: "#003545",
                    ":hover": {
                      bgcolor: "#002834",
                      color: "white",
                    },
                  }
                : {
                    bgcolor: "white",
                    borderColor: "#7F99A1",
                    color: "#003444",
                    ":hover": {
                      bgcolor: "#E5E5E5",
                      borderColor: "#E5E5E5",
                      color: "#525252",
                    },
                  }),
            }}
          >
            {isNew && (
              <Typography variant="button" fontWeight={700}>
                NY
              </Typography>
            )}
            <SaveAltOutlinedIcon sx={{ fontSize: "18px" }} />
          </Button>
        ) : null}
      </TableCell>
    </TableRow>
  );
};

export default Row;
