import { memo, useCallback, useState, useMemo } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Stack,
  SxProps,
  Pagination,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";

import LabeledSpinner from "@/components/LabeledSpinner";
import useDebounce from "@/hooks/useDebounce";
import useSettings from "@/hooks/useSettings";
import { Nullish } from "@/utils/types";

import SearchBar from "@/extensions/klarahill/components/SearchBar";
import { Case } from "@/extensions/klarahill/types";
import {
  ObituarySymbolFilter,
  ObituarySymbolType,
  ObituarySymbolOrImage,
  OBITUARY_SYMBOLS_PAGE_SIZE,
} from "@/extensions/obituary/types";
import useImagesQuery from "@/extensions/obituary/queries/useImagesQuery";
import useSymbolsQuery from "@/extensions/obituary/queries/useSymbolsQuery";
import useSymbolsOptionsQuery from "@/extensions/obituary/queries/useSymbolsOptionsQuery";

import ImageLibrary from "./ImageLibrary";

const styles: Record<string, SxProps> = {
  spinner: { minWidth: 550, height: "calc(90vh + 56px + 52.5px)" },
  pagination: {
    alignItems: "center",
    justifyContent: "center",
    p: 2,
    width: "100%",
    bgcolor: "grey.200",
  },
};

export interface ImageLibraryDialogProps
  extends Omit<DialogProps, "onClose" | "onSubmit" | "onChange"> {
  c: Case;
  onClose: () => void;
  onSubmit: (symbol: ObituarySymbolOrImage) => void;
}

const ImageLibraryDialog: React.FC<ImageLibraryDialogProps> = ({
  c,
  onClose,
  open,
  onSubmit,
  ...props
}) => {
  const [selected, setSelected] = useState<Nullish<ObituarySymbolOrImage>>();

  const { settings: filter, setSettings: setFilter } =
    useSettings<ObituarySymbolFilter>({});

  const debouncedSetFilter = useDebounce(setFilter, 1000);

  const images = useImagesQuery(c.id);
  const options = useSymbolsOptionsQuery();
  const symbols = useSymbolsQuery(filter);

  const isLoading = images.isLoading || options.isLoading;

  const pages = useMemo(
    () => Math.floor((symbols.data?.count ?? 0) / OBITUARY_SYMBOLS_PAGE_SIZE),
    [symbols?.data?.count],
  );

  /// Callbacks

  const handleSubmit = useCallback(() => {
    if (selected == null) return;
    onSubmit(selected);
    onClose();
  }, [selected, onSubmit, onClose]);

  const handleSelectImage = useCallback(
    (symbol: ObituarySymbolOrImage) => setSelected(symbol),
    [],
  );

  const handleSearchChange = useCallback(
    (query: string) =>
      debouncedSetFilter({
        search: query === "" ? undefined : query,
        page: undefined,
      }),
    [debouncedSetFilter],
  );

  const handleSearchSubmit = useCallback(() => symbols.refetch(), [symbols]);

  const handleToggleType = useCallback(
    (_: unknown, value: ObituarySymbolType) => {
      const type = filter?.type === value ? undefined : value;
      setFilter({ type, page: undefined });
    },
    [filter, setFilter],
  );

  const handleToggleHasColor = useCallback(() => {
    setFilter({
      has_color: filter?.has_color ? undefined : true, // We only want true, nullish to omit.
      page: undefined,
    });
  }, [filter]);

  const handlePagination = useCallback(
    (_: React.ChangeEvent<unknown>, page: number) => setFilter({ page }),
    [],
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.target instanceof HTMLInputElement) return;

      if (event.key === "Enter" && selected != null) {
        event.preventDefault();
        handleSubmit();
      }
    },
    [selected, handleSubmit],
  );

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={onClose}
      onKeyDown={handleKeyDown}
      {...props}
    >
      {isLoading ? (
        <LabeledSpinner label="Hämtar symboler..." sx={styles.spinner} />
      ) : (
        <>
          <Stack direction="row">
            <SearchBar
              onChange={handleSearchChange}
              onSubmit={handleSearchSubmit}
              placeholder="Sök efter motiv, bildtyp, symbolnamn eller nummer"
              defaultValue={filter?.search}
            />

            <Stack direction="row" gap={2} sx={{ m: 1 }}>
              <ToggleButtonGroup
                exclusive
                onChange={handleToggleType}
                value={filter?.type}
                aria-label="Filtrera på symboltyp"
              >
                <ToggleButton disableRipple value="photo">
                  Foto
                </ToggleButton>
                <ToggleButton disableRipple value="clipart">
                  Illustration
                </ToggleButton>
                <ToggleButton disableRipple value="logotype">
                  Logotyp
                </ToggleButton>
              </ToggleButtonGroup>

              <ToggleButtonGroup
                exclusive
                onChange={handleToggleHasColor}
                value={filter?.has_color}
                aria-label="Filtrera på färg"
              >
                <ToggleButton value={true}>Färg</ToggleButton>
              </ToggleButtonGroup>
            </Stack>
          </Stack>

          {symbols.isLoading || symbols.data == null ? (
            <LabeledSpinner
              label="Hämtar symboler..."
              sx={{ ...styles.spinner, bgcolor: "grey.200" }}
            />
          ) : (
            <DialogContent dividers sx={{ p: 0, m: 0 }}>
              <ImageLibrary
                filter={filter}
                images={images?.data ?? []}
                symbols={symbols?.data?.results ?? []}
                selected={selected}
                onSelectImage={handleSelectImage}
                {...props}
              />

              {pages > 1 && (
                <Stack sx={styles.pagination}>
                  <Pagination
                    onChange={handlePagination}
                    variant="outlined"
                    count={pages}
                    page={filter?.page ?? 1}
                    shape="rounded"
                  />
                </Stack>
              )}
            </DialogContent>
          )}

          <DialogActions>
            {onClose && <Button onClick={onClose}>Avbryt</Button>}
            <Button disabled={selected == null} onClick={handleSubmit}>
              Välj symbol
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default memo(ImageLibraryDialog);
