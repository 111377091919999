import React, { memo, useCallback, useMemo } from "react";
import { Stack } from "@mui/material";

import {
  Editable,
  Item,
} from "@/extensions/obituary/pages/detail/Story/shared";

import { useStory } from "../context";
import EditableItem from "./EditableItem";
import { Container, isContainer } from "./Container";
import Singleline, {
  isSingleline,
  isVariant,
  makeSingleline,
  relativeVariantOptions,
} from "./Singleline";

export function isRow(item: Item): item is Container {
  return isContainer(item) && item.variant.includes("row");
}

const Edit: Editable<Container> = ({ item, path }) => {
  const { children, hidden, variant } = item;
  const { change } = useStory();

  const childVariant = useMemo(() => {
    const child = children.find(isSingleline);
    return child?.variant;
  }, [children]);

  const handleChangeVariant = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const variant = event.target.value;
      if (!isVariant(variant)) {
        console.error("Invalid variant", variant);
        return;
      }

      const newChildren = children.map((child) => {
        if (!isSingleline(child)) return child;
        return { ...child, variant };
      });

      change<Container>(path, { children: newChildren });
    },
    [change, children],
  );

  const handleSplit = useCallback(
    (split: -1 | 1) => {
      const newChildren =
        split === -1
          ? children.slice(0, -1)
          : [...children, makeSingleline({ variant: childVariant })];

      change<Container>(path, { children: newChildren });
    },
    [change, children],
  );

  const variantProps = useMemo(() => {
    return {
      variant: childVariant,
      variants: relativeVariantOptions,
      onVariantChange: handleChangeVariant,
    };
  }, [variant, handleChangeVariant]);

  return (
    <EditableItem
      title="Row"
      path={path}
      hidden={hidden}
      actions="mutate"
      onSplit={handleSplit}
      splits={children.length}
      {...variantProps}
    >
      <Stack direction="row" gap={1} width="100%" sx={{ "& > *": { flex: 1 } }}>
        {children?.map((child, i) =>
          !isSingleline(child) ? null : (
            <Singleline
              noHeader
              item={child}
              key={child.id}
              path={[...path, "children", i]}
            />
          ),
        )}
      </Stack>
    </EditableItem>
  );
};

export default memo(Edit);
