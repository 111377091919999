/**
 * We could use `setSearchParams({ tab })`, but Remix/React Router
 * considers this navigation, triggering a full reload.
 * See: https://github.com/remix-run/react-router/issues/8908
 * This is undesirable in our case, so we'll simply do it manually.
 */
export function setManually(key: string, value: string): void {
  const url = new URL(window.location.href);
  url.searchParams.set(key, value);
  window.history.replaceState({}, "", url.toString());
}
