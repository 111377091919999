type Stringifyable = Parameters<typeof JSON.stringify>[0];

const encoder = new TextEncoder();

/**
 * Hash arbitary object. Defauts `algorithm` to `"SHA-256"`.
 */
export async function object(
  obj: Stringifyable,
  algorithm: AlgorithmIdentifier = "SHA-256",
): Promise<string> {
  const data = encoder.encode(JSON.stringify(obj));
  const hash = await crypto.subtle.digest(algorithm, data);
  return Array.from(new Uint8Array(hash))
    .map((byte) => byte.toString(16).padStart(2, "0"))
    .join("");
}
