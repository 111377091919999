import { useMemo, useState } from "react";
import * as Bananas from "bananas-commerce-admin";
import {
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import SaveAltOutlinedIcon from "@mui/icons-material/SaveAltOutlined";

import ErrorCard from "@/extensions/klarahill/components/ErrorCard";
import { Case, Gift } from "@/extensions/klarahill/types";
import * as styles from "@/extensions/klarahill/pages/case/shared";
import Row from "./Row";

export const Gifts: React.FC<styles.CaseTabProps> = ({ case: c }) => {
  const api = Bananas.useApi();
  const [currentCase, setCurrentCase] = useState<Case>(c);

  const [
    gifts,
    downloadableGiftIds,
    downloadableNewGiftIds,
    downloadableGiftsURL,
    downloadableNewGiftsURL,
  ] = useMemo(() => {
    const operation = api.operations["case.case:get-gift-pdf"];
    if (!operation) {
      throw new Error('No action found for "case.case:get-gift-pdf"');
    }

    const gifts = currentCase.gifts
      .sort((a: Gift, b: Gift) => b.id - a.id)
      .map((gift) => {
        if (gift.status !== "captured" && gift.status !== "disbursed") {
          return { ...gift, certificateUrl: null };
        }

        return {
          ...gift,
          certificateUrl: operation.url({ params: { gift_ids: [gift.id] } }),
        };
      });

    let downloadableGiftIds,
      downloadableNewGiftIds,
      downloadableGiftsURL,
      downloadableNewGiftsURL;

    if (gifts.length > 1) {
      const downloadableGifts = gifts.filter(
        (gift) => gift.certificateUrl != null,
      );
      downloadableGiftIds = downloadableGifts.map((gift) => gift.id);
      downloadableNewGiftIds = downloadableGifts
        .filter((gift) => gift.certificate_download_date == null)
        .map((gift) => gift.id);

      downloadableGiftsURL = operation.url({
        params: { gift_ids: downloadableGiftIds },
      }).href;

      downloadableNewGiftsURL =
        downloadableNewGiftIds.length > 0
          ? operation.url({ params: { gift_ids: downloadableNewGiftIds } }).href
          : null;
    }

    return [
      gifts,
      downloadableGiftIds ?? [],
      downloadableNewGiftIds ?? [],
      downloadableGiftsURL,
      downloadableNewGiftsURL,
    ];
  }, [currentCase]);

  const refreshGiftStatus =
    (...giftIds: number[]) =>
    () => {
      setCurrentCase((currentCase) => ({
        ...currentCase,
        gifts: currentCase.gifts.map((gift) =>
          giftIds.includes(gift.id)
            ? {
                ...gift,
                certificate_download_date: new Date().toISOString(),
              }
            : gift,
        ),
      }));

      setTimeout(async () => {
        const operation = api.operations["case.case:retrieve"];
        if (!operation) {
          throw new Error('No action found for "case.case:retrieve"');
        }

        const response = await operation.call({
          params: { case_id: currentCase.id },
        });
        setCurrentCase(await response.json());
      }, 5000);

      return true;
    };

  return gifts && gifts.length === 0 ? (
    <Box sx={styles.content}>
      <Box sx={{ ...styles.cards }}>
        <ErrorCard errorMessage="Det finns inga minnesgåvor för det här begravningsärendet." />
      </Box>
    </Box>
  ) : (
    <>
      <Box sx={styles.content}>
        <Box sx={styles.cards}>
          <Grid item xs={12} sx={{ width: "100%" }}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ width: "0" }}>
                      <Typography
                        sx={{ fontWeight: 500, paddingLeft: "5px" }}
                        variant="body2"
                      >
                        Status
                      </Typography>
                    </TableCell>

                    <TableCell sx={{ width: "50%" }}>
                      <Typography sx={{ fontWeight: 500 }} variant="body2">
                        Namn
                      </Typography>
                    </TableCell>

                    <TableCell sx={{ width: "50%" }}>
                      <Typography sx={{ fontWeight: 500 }} variant="body2">
                        Mottagare
                      </Typography>
                    </TableCell>

                    <TableCell
                      sx={{ paddingRight: 3, width: "0" }}
                      align="right"
                    >
                      <Typography sx={{ fontWeight: 500 }} variant="body2">
                        Gåvobevis
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {gifts?.map((gift, i) => (
                    <Row
                      key={i}
                      gift={gift}
                      onClick={refreshGiftStatus(gift.id)}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Box>
      </Box>

      {Boolean(
        gifts.some(({ certificateUrl }) => certificateUrl != null) &&
          downloadableGiftsURL != null,
      ) && (
        <Bananas.ActionBar>
          <Stack direction="row" sx={styles.actionBar}>
            <Button
              variant="outlined"
              startIcon={<SaveAltOutlinedIcon />}
              href={downloadableGiftsURL}
              onClick={refreshGiftStatus(...downloadableGiftIds)}
            >
              Ladda ned alla gåvobevis
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<SaveAltOutlinedIcon />}
              href={downloadableNewGiftsURL ?? ""}
              onClick={refreshGiftStatus(...downloadableNewGiftIds)}
              disabled={downloadableNewGiftsURL == null}
            >
              Ladda ned nya gåvobevis
            </Button>
            )
          </Stack>
        </Bananas.ActionBar>
      )}
    </>
  );
};
